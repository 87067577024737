import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Alert, Spin} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class LinkFileExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    cancel = () => {
        this.props.hide();
    }
    render() {
        return (
            <Modal
                title="Kết quả export"
                visible={this.props.visible}
                onCancel={this.cancel}
                footer={null}
            >
                {
                    !this.props.link ? <div className="text-center"><Spin size="large" /> </div>: <div>
                        <Alert message={'Chú ý link chỉ tồn tại trong vòng 30 phút'} type="warning" className="mb-20"/>
                
                        <a href={this.props.link} target="_blank">{this.props.link}</a>
                        <div className="text-center">
                            <a href={this.props.link} target="_blank" className="btn btn-primary">Download</a>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}
