import { Button, Form, Input, message, Modal, notification, Radio, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "../../../lib/helpers";
import { API_URL } from "../../../lib/api";

const WidgetFormScriptForAll = (props) => {
    const { visible, hide } = props;
    const [loading, setloading] = useState(false);

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);
            let response = await fetchData({
                url: API_URL.admin.setting.index,
                method: "post",
                body: JSON.stringify(values)
            });
            hide();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const getSetting = async () => {
        try {
            setloading(true);
            let response = await fetchData({
                url: API_URL.admin.setting.index,
                method: "get"
            });
            formRef.current.setFieldsValue({
                script_for_all: response.data ? response.data.script_for_all : undefined
            })
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    }

    useEffect(() => {
        if (visible) {
            getSetting();
        }

    }, [visible])

    return (
        <Modal
            title="Script for all"
            visible={visible}
            onCancel={hide}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={formRef}
            >
                <Form.Item name="script_for_all" label="Script">
                    <Input.TextArea rows={6}/>
                </Form.Item>
                <Form.Item className="d-flex mb-0 justify-content-end">
                    <Button onClick={hide} className="mr-2">
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispathToProps)(WidgetFormScriptForAll);
