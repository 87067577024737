import React, { useEffect, useState } from "react";
import { Upload, message, Modal, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { API_URL } from "../../../../../lib/api";
import { AccountMetaData } from "../../../../../lib/account";

const WidgetUpload = (props) => {
    const [listFile, setListFile] = useState([]);
    const [visiblePreview, setvisiblePreview] = useState({
        visible: false
    })

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const propsUploadImage = {
        name: "file",
        accept: "image/*",
        action: process.env.REACT_APP_API + '/' + API_URL.upload,
        headers: {
            authorization: `JWT ${AccountMetaData.getToken()}`,
        },
        listType: "picture-card",
        onChange: (info) => {
            if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
                return;
            }
            setListFile(info.fileList);
        },

        onPreview: async (file) => {
            if (!file.url && !file.preview && !file.thumbUrl) {
                file.preview = await getBase64(file.originFileObj);
            }
            setvisiblePreview({
                visible: true,
                url: file.url || file.preview || file.thumbUrl,
                filename: file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            })
        },
        fileList: listFile,
    };

    useEffect(() => {
        setListFile(props.fileList || [])
    }, [props.fileList])

    return <React.Fragment>
        <Form.Item
            label={ props.label || "File đính kèm" }
            name={ props.name || "postImage" }
            extra="(* Tối đa 5 ảnh *)"
        >
            <Upload {...propsUploadImage} disabled={props.disabled}>
                {listFile.length >= 5 ? null : (
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                )}
            </Upload>
        </Form.Item>
        <Modal
            visible={visiblePreview.visible}
            title={visiblePreview.filename}
            footer={null}
            onCancel={() => setvisiblePreview({ visible: false })}
        >
            <img
                alt="example"
                style={{ width: "100%" }}
                src={visiblePreview.url}
            />
        </Modal>
    </React.Fragment>
}

export default WidgetUpload;