import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, TreeSelect, Alert} from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class SyncUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            groupData: [],
            groupSelected: null,
            data_success: [],
            data_error: []
        }
        this.form = null;

    }
    componentDidMount = () => {
        
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true, data_success: [],  data_error: []});

            let group = await window.extfbase.request({ method: 'mhcp_account', data: {
                uss_token: this.props.userSystem.token_us,
                action: 'get.hashgroup',
                nodeId: values['group_id']
            }});
            this.setState({
                groupSelected: group.data
            })
            let data = [];
            let after = 0;

            while(true){
                let response = await window.extfbase.request({ method: 'mhcp_account', data: {
                    uss_token: this.props.userSystem.token_us,
                    action: 'get.lists',
                    hashgroup: values['group_id'],
                    after: after,
                    before: 0
                }});
                response = response.data;
                data = data.concat(response.data);
                if(response.data.length == 0 || !this.state.loading) break;
                after = response.data[response.data.length - 1]['id'];
            }
            while(data.length > 0){
                if(!this.state.loading) break;
                let item = data.splice(0, 10);
                let promise = [];
                for(let key in item){
                    promise.push(this.saveDataSync(item[key]));
                }
                await Promise.all(promise);
            }
            this.setState({ loading: false });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    message: 'Lấy danh sách group us'
                })
            });
        }
    };
    cancel = () => {
        this.props.resetData();
        this.setState({
            groupSelected: null,
            loading: false
        })
        this.props.hide();
    }

    sleep = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(ms);
            }, ms)
        });
    }
    componentWillUpdate = (nextProps, nextState) => {
        if(nextProps.visible && nextProps.visible != this.props.visible){
            if(!this.props.userSystem.token_us){
                this.props.notification({ 
                    description: 'Vui lòng cập nhập token us',
                    type: 'error',
                    message: 'Đồng bộ dữ liệu us'
                })
            } else{
                this.getDataGroup();
            }
        }
    }
    getDataGroup = async () => {
        try{
            let response = await window.extfbase.request({ method: 'mhcp_account', data: {
                uss_token: this.props.userSystem.token_us,
                action: 'get.hashgroups'
            }});
            response = response.data;
            this.setState({
                groupData: this.parseGroup(response.data)
            })
        } catch(err){
            console.log(err);
            this.props.notification({ 
                description: err.message || 'Đã có lỗi xảy ra',
                type: 'error',
                message: 'Lấy danh sách group us'
            })
        }
    }
    parseGroup = (list) => {
        var roots = [];

        for (let i = 0; i < list.length; i++) {
            let result = {
                title: list[i]['name'],
                value: list[i]['id'],
                children: []
            }
           if(list[i]['childs']['node'].length > 0){
                 let childs = list[i]['childs']['node'];
                 childs.forEach((item) => {
                    result['children'].push({
                        title: item.name,
                        value: item.id
                    })
                 })
           }
           roots.push(result)
        }
        return roots;    
    }
    saveDataSync = async (item) => {
        return new Promise(async (resolve, reject) => {
            try{
                let data = {
                    uid: item.uid,
                    data: item,
                    folder_id: this.props.currentFolder,
                    uss_token: this.props.userSystem.token_us
                }
                // try{
                //     let response = await window.extfbase.request({ method: 'mhcp_data', data: {
                //         uss_token: this.props.userSystem.token_us,
                //     }, params: `action=export&type=full&webview=true&nodeId=${item.id}`});
                //     data['file_backup'] = response.data;
                // } catch(err){

                // }
                await this.props.fetchData({
                    url: 'api/v1/user_fb/sync_us',
                    method: 'post',
                    body: JSON.stringify(data),
                    headers: { 'Content-Type': 'application/json' }
                });
                this.state.data_success.push(item);
                this.setState({reload: !this.state.reload})
                return resolve(true)
            } catch(err){
                this.state.data_error.push(item);
                this.setState({reload: !this.state.reload})
                return resolve(false);
            }
        })
    }
    render() {
        return (
            <Modal
            title="Đồng bộ dữ liệu từ us"
            visible={this.props.visible}
            onCancel={this.cancel}
            footer={null}
            >
                <Form
                        onFinish={this.onFinish}
                        ref={(evt) => this.form = evt}
                        layout="vertical"
                    >
                        {
                                !window.extfbase || !window.extfbase.isConnected ? 
                                <Alert message={'Extension fbase chưa được connect, vui lòng thử lại!'} type="warning" className="mb-20"/>
                                : null
                            }
                        <Form.Item
                            label="Chọn nhóm tài khoản"
                            name="group_id"
                            rules={[{ required: true, message: 'Bạn phải chọn nhóm!' }]}
                        >

                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={this.state.groupData}
                                placeholder="Tìm tên hoặc id nhóm"
                                treeDefaultExpandAll={false}
                                allowClear
                                showSearch
                                disabled={this.state.loading}
                                filterOption={(input, option) =>  {
                                    if(option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value == input){
                                        return option
                                    }
                                }}
                            />
                        </Form.Item>
                        {
                            this.state.groupSelected ? 
                            <div className="d-flex" style={{justifyContent: "space-between"}}>
                                <p>
                                    Tổng số: {this.state.groupSelected.count}
                                </p>
                                <p>
                                    Thành công: <span className="text-success">{this.state.data_success.length}</span>
                                </p>
                                <p>
                                    Lỗi: <span className="text-danger">{this.state.data_error.length}</span>
                                </p>
                            </div> : null
                        }
                        <div className="text-center">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" disabled={!window.extfbase || !window.extfbase.isConnected ? true : false}>
                                Cập nhập
                            </Button>
                            {
                                this.state.loading ?
                                <Button type="info" className="ml-10" onClick={() => {
                                    this.setState({ loading: false })
                                }}>
                                    Dừng tiến trình
                                </Button> : null
                            }
                        </div>
                    </Form>
            </Modal>
        )
    }
}
