export const ROOT_ACTION = {
    platform: {
        action: {
            get: "GET_FLATFORM_ACTION",
            getSuccess: "GET_FLATFORM_ACTION_SUCCESS",
            getError: "GET_FLATFORM_ACTION_ERROR"
        },
        updateSate: "PLATFORM_UPDATE_STATE"
    },
    taskAction: {
        viewDetail: "TASK_ACTION_VIEW_DETAIl"
    }
}