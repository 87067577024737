import { Button, Form, Input, message, Modal, notification, Radio, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "../../../../lib/helpers";
import { API_URL } from "../../../../lib/api";
import { ListKeySystemData } from "../../../../utils/config";

const WidgetForm = (props) => {
    const { visible, hide, Access } = props;
    const [loading, setloading] = useState(false);
    const [ type, setType ] = useState(ListKeySystemData[0]);

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            let data = {
                title: values.title,
                key: values.key
            }

            if(values.key == "token_facebook"){
                data['value'] = JSON.stringify({
                    token: values.token,
                    cookie: values.cookie
                })
            } else if(values.key == "proxy_facebook"){
                data['value'] = values.proxy
            }

            let url = values._id ? `${API_URL.admin.systemData.index}/${values._id}` : API_URL.admin.systemData.index

            let response = await fetchData({
                url,
                method: values._id ? "put" : "post",
                body: JSON.stringify(data)
            });
            
            if(props.onFinish) props.onFinish();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (visible) {
            setType(ListKeySystemData[0]);
            formRef.current.resetFields();
            if(props.item){
                formRef.current.setFieldsValue({
                    ...props.item,
                    ...JSON.parse(props.item.value)
                });
            }
        }

    }, [visible])

    return (
        <Modal
            title="Cập nhập dữ liệu"
            visible={visible}
            onCancel={hide}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={formRef}
                initialValues={{
                    key: ListKeySystemData[0],
                    title: "Tài Nguyên"
                }}
            >
                <Form.Item name="_id" noStyle>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item name="key" label="Loại"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Select placeholder="Chọn loại dữ liệu"
                        onChange={(e) => setType(e)}
                    >
                        {
                            ListKeySystemData.map((item) => {
                                return <Select.Option value={item}>{item}</Select.Option>       
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="title" label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Input placeholder="Nhập tên dữ liệu"/>
                </Form.Item>
                {
                    type == "token_facebook" ?
                    <>
                        <Form.Item name="token" label="Token">
                            <Input placeholder="Nhập token"/>
                        </Form.Item>
                        <Form.Item name="cookie" label="Cookie">
                            <Input.TextArea placeholder="Nhập cookie"
                                rows={3}
                            />
                        </Form.Item>
                    </> : type == "proxy_facebook" ?
                    <>
                        <Form.Item name="proxy" label="Proxy">
                            <Input placeholder="Ip:port:user:password"/>
                        </Form.Item>
                    </>
                    : null
                }
                <Form.Item className="d-flex mb-0 justify-content-end">
                    <Button onClick={hide} className="mr-2">
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispathToProps)(WidgetForm);
