import React, { useState, useEffect, useMemo } from 'react';
import { Button, Drawer, Modal, notification, Radio, Space, Table, Tag, Tooltip } from 'antd';
import { Config } from '../../../utils/config';
import { fetchData } from '../../../lib/helpers';
import { API_URL } from '../../../lib/api';
import { connect } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const WidgetLogAction = (props) => {
    const { onClose, visible } = props;
    const [placement, setPlacement] = useState('right');
    const [listData, setlistData] = useState({
        total: 0,
        data: []
    });
    const [loading, setloading] = useState(false);
    const [pagination, setpagination] = useState({
        limit: 10,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);

    const { Platform } = props.Access;

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination,
                status: 1,
                uid: props.data.uid
            }
            let response = await fetchData({
                url: API_URL.aciton.list,
                params: params
            });
            setlistData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
            setselectedRowKeys([]);
        }
    };

    useEffect(() => {
        if (props.data && visible) {
            getListData();
        }
    }, [pagination, visible]);

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.aciton.delete,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    return (
        <>
            <Drawer
                title={`Chi tiết hành động ${props.data && props.data.uid}`}
                placement={placement}
                width={900}
                onClose={onClose}
                open={visible}
                extra={
                    <Space>
                        <Button onClick={onClose}>Đóng</Button>
                    </Space>
                }
                bodyStyle={{
                    paddingTop: "0px"
                }}
            >
                <div className='mt-10 mb-10 d-flex'>
                    <Tag className='mr-0'>Tất cả: {listData.total} </Tag>
                    <Button type="primary" size="small"
                        onClick={getListData}
                        className="ml-10"
                        disabled={loading}
                    >
                        <i className="far fa-refresh"></i>
                    </Button>
                    <Button type="primary" size="small" danger
                        onClick={() => deleteMany(selectedRowKeys)}
                        className="ml-10"
                        disabled={!selectedRowKeys.length}
                    >
                        <i className="far fa-trash"></i>
                    </Button>
                </div>
                <Table
                    dataSource={listData.data}
                    size="small"
                    columns={[
                        {
                            title: `(${selectedRowKeys.length}) Tập lệnh`,
                            dataIndex: "action_type",
                            render: (value, record) => {
                                return value
                            }
                        },
                        {
                            title: "DeviceId / Action",
                            dataIndex: "action_type",
                            render: (value, record) => {
                                let action_type = Platform.actions.find((item) => item.key == record.action_name)
                                return `${record.device_id} / ${action_type ? action_type.value : record.action_name}`
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (value, record) => {
                                return <>
                                    <Tag color={Config.statusAction[value].color}>{Config.statusAction[value].label}</Tag>
                                </>
                            }
                        },
                        {
                            title: "Thời gian",
                            dataIndex: "start_time",
                            render: (value, record) => {
                                return <>
                                    {
                                        record.last_time ? <span>{moment(record.last_time).fromNow()} - </span> : null
                                    }
                                    {moment(record.start_time || record.created_time).format("HH:mm DD/MM/YYYY")}
                                </>
                            }
                        },
                        {
                            title: "Thao tác",
                            align: "right",
                            render: (value, record) => {
                                return <React.Fragment>
                                    <div className="">
                                        {
                                            record.task && <Tooltip title="Chi tiết chiến dịch">
                                                <Button type="primary" size="small" className="mb-2"
                                                    onClick={() => {
                                                        window.open(`/task_action/${record.task._id}?open_setting=1`)
                                                    }}
                                                >
                                                    <i className='far fa-eye'></i>
                                                </Button>
                                            </Tooltip>
                                        }
                                        <br></br>
                                        <Button type="primary" size="small" danger
                                            onClick={() => deleteMany([record._id])}
                                            className="ml-2"
                                            disabled={record.status != 1}
                                        >
                                            <i className="far fa-trash"></i>
                                        </Button>
                                    </div>
                                </React.Fragment>
                            }
                        }
                    ]}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: (e) => setselectedRowKeys(e),
                        getCheckboxProps: (record) => ({
                            disabled: record.status != 1
                        })
                    }}
                    rowKey="_id"
                    pagination={{
                        total: listData.total,
                        pageSize: pagination.limit,
                        current: pagination.page,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100, 500],
                        onChange: (page, pageSize) => {
                            setpagination({
                                limit: pageSize,
                                page: page,
                            });
                        },
                        position: ["bottomRight"],
                        size: "small"
                    }}
                    loading={loading}
                    scroll={{
                        x: true
                    }}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(WidgetLogAction);