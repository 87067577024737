import { Modal, Form, Button, Input, Select, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { Config } from "../../../utils/config";

const WidgetFormUpdate = (props) => {
    const [loading, setloading] = useState(false);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            await fetchData({
                url: API_URL.Ref.index + "/" + values._id,
                method: "put",
                body: JSON.stringify(values),
            });

            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công",
            });
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err,
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (!props.visible && form.current) {
            form.current.resetFields();
        }
        if (props.visible) {
            form.current.setFieldsValue(props.item);
        }
    }, [props.visible]);

    return (
        <Modal
            title="Cập nhập"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form layout="vertical" onFinish={onFinish} ref={form}>
                <Form.Item name={"_id"} noStyle>
                    <Input type={"hidden"} />
                </Form.Item>
                <Form.Item
                    name="status"
                    label="Trạng thái"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống",
                        },
                    ]}
                >
                    <Select>
                        {Object.keys(Config.statusUser).map((item) => {
                            return (
                                <Select.Option value={parseInt(item)}>
                                    {Config.statusUser[item]["label"]}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                    <Input.TextArea />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default WidgetFormUpdate;
