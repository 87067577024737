import React, { useEffect, useMemo, useState, useRef } from "react";
import { Empty, Image, Spin } from "antd";
import moment from "moment";
import { getImageApi } from "../../../lib/helpers";
import { PopupMenu } from "../../../component";
import { getPosContextmenu } from "../../../lib/app";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import Selecto from "react-selecto";

const WidgetDeviceGridView = (props) => {
    const { data, loading, selectedRowKeys, setselectedRowKeys, setvisibleFormScriptRemote,
        setvisibleFormScript, deleteMany, setvisibleFormUpdate, roleTypeAdmin
    } = props;

    const [reload, setreload] = useState(false);
    const [dataPopup, setdataPoup] = useState({});
    const [scrollOptions, setScrollOptions] = useState({});
    const [ selectedRowKeysTemp, setselectedRowKeysTemp ] = useState([]);

    const dataSelecto = useRef({});
    const selectoRef = useRef();
    const scrollerRef = useRef(window);
    const refTimerReload = useRef();

    useEffect(() => {
        if(refTimerReload.current) clearTimeout(refTimerReload.current);
        refTimerReload.current = setTimeout(() => {
            setreload(!reload)
        }, 6000)
    }, [reload])

    useEffect(() => {
        setScrollOptions({
            container: document.body,
            throttleTime: 6,
            threshold: 0,
        });
    }, [])

    const selectedDevice = useMemo(() => {
        return data
            .filter((item) => selectedRowKeys.indexOf(item._id) > -1)
            .map((item) => item.profile_id)
    }, [selectedRowKeys, data])

    return !data.length ? <Empty description="No data" className="pb-10"/> : <React.Fragment>
        <PopupMenu hide={() => {
            setdataPoup({});
            // let data = [];
            // selectedRowKeys.forEach((item) => {
            //     let item_ = document.querySelector(`#grid-view-device .view-device-item[data-id="${item}"]`);
            //     if(item_) data.push(item_);
            // })
            // selectoRef.current.selecto.selectedTargets = data;

            // setselectedRowKeys(selectedRowKeys);

        }} {...dataPopup} menu={dataPopup.item && <React.Fragment>
            <ul onContextMenu={(e) => e.preventDefault()}>
                <li style={{ pointerEvents: "none", background: "#f0f2f5" }}>Thiết bị đã chọn: {selectedRowKeys.length || 1}</li>
                <li className="border-top"
                    onClick={(e) => {
                        setvisibleFormScriptRemote({
                            visible: true,
                            devices: selectedDevice.length ? selectedDevice : [dataPopup.item.profile_id]
                        })
                    }
                    }
                >Tạo lệnh remote</li>
                <li
                    onClick={() => {
                        setvisibleFormScript({
                            visible: true,
                            devices: selectedRowKeys.length ? selectedRowKeys : [dataPopup.item._id],
                            item: selectedRowKeys.length ? null : dataPopup.item
                        })
                    }
                    }
                >Gán script</li>
                <li onClick={() => {
                    selectoRef.current.selecto.selectedTargets = [];
                    setselectedRowKeys([]);
                }}>
                    Bỏ chọn device
                </li>
                {/* <li
                    style={{ pointerEvents: !selectedRowKeys.length && "none" }}
                    onClick={() => {
                        setselectedRowKeys([]);
                    }}
                >Bỏ device đã chọn</li> */}
                {/* <li className="border-top">
                    <span>Điều hướng</span>
                    <i className="far fa-chevron-right"></i>
                    <ul className="ul-children">
                        
                    </ul>
                </li> */}
                {/* <li onClick={() => {
                    setvisibleFormUpdate({ visible: true, item: dataPopup.item })
                }}>
                    Chỉnh sửa
                </li> */}
                {
                    dataPopup.item && dataPopup.item.profile_id && <>
                    <li className="border-top"
                        onClick={() => {
                            props.history.push(`${roleTypeAdmin ? '/admin/actions' : '/actions'}?device_id=${dataPopup.item.profile_id}`)
                        }}
                    >
                        Log Action
                    </li>
                    <li
                        onClick={() => {
                            props.history.push(`/app/screen/?device_id=${dataPopup.item.profile_id}`)
                        }}
                    >
                        Screen
                    </li>
                    <li
                        onClick={() => {
                            props.history.push(`/?device_ids=${dataPopup.item.profile_id}`)
                        }}
                    >
                        Quản lý Nick
                    </li>
                    </>
                }
                <li onClick={() => {
                    deleteMany(selectedRowKeys.length ? selectedRowKeys : [dataPopup.item._id])
                }}>Xóa thiết bị</li>
            </ul>
        </React.Fragment>} />
        <Spin spinning={loading}>
            <div id="grid-view-device" style={{ minHeight: "300px" }}
                onContextMenu={(e) => {
                    if (selectedRowKeys.length) {
                        let pos = getPosContextmenu(e);
                        if (pos.y) {
                            pos.y -= 33;
                        }
                        setdataPoup({
                            ...pos,
                            visible: true,
                            item: {
                                _id: "defalut"
                            }
                        })
                    }
                }}
            >
                <div className="row">
                    {
                        data && data.map((item, i) => {
                            return <div className="col-6 col-sm-4 col-md-3 col-lg-2" key={i}>
                                <div
                                    className={cx("view-device-item", selectedRowKeys.indexOf(item._id) > -1 && "active")}
                                    onContextMenu={(e) => {
                                        if (!selectedRowKeys.length) {
                                            let pos = getPosContextmenu(e);
                                            if (pos.y) {
                                                pos.y -= 33;
                                            }
                                            setdataPoup({
                                                ...pos,
                                                visible: true,
                                                item: item
                                            })
                                        }
                                    }}
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     handleActive(item._id)
                                    // }}
                                    data-id={item._id}
                                >
                                    <div className="view-device-head">
                                        {item.profile_id}
                                    </div>
                                    <div className="view-device-img">
                                        <Image
                                            preview={false}
                                            src={getImageApi(item.last_screen, true)}
                                            // key={Date.now()}
                                        />
                                    </div>
                                    <div className="view-device-info">
                                        <p>{item.device_group} | {moment(item.last_time).fromNow()}</p>
                                        <p>Ver: {item.app_version}</p>
                                        {
                                            item.info && <>
                                                <p>Data type: {item.info.data_type}</p>
                                                <p>Signal Strength: {item.info.signal_strength}</p>
                                                <p>Ip Wan: {item.info.ip_wan}</p>
                                                <p>Ip WiFi: {item.info.ip_wifi}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </Spin>
        <Selecto
            ref={selectoRef}
            container={document.querySelector("#grid-view-device")}
            // The area to drag selection element (default: container)
            dragContainer={window}
            // Targets to select. You can register a queryselector or an Element.
            selectableTargets={[".view-device-item", document.querySelector(".view-device-item")]}
            // Whether to select by click (default: true)
            selectByClick={true}
            // Whether to select from the target inside (default: true)
            selectFromInside={true}
            // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
            continueSelect={true}
            // continueSelectWithoutDeselect={true}
            // Determines which key to continue selecting the next target via keydown and keyup.
            toggleContinueSelect={"shift"}
            // The container for keydown and keyup events
            keyContainer={window}
            // The rate at which the target overlaps the drag area to be selected. (default: 100)
            hitRate={100}
            scrollOptions={scrollOptions}
            onSelectEnd={e => {
                console.log(e);
                let data = [];
                e.selected.forEach(el => {
                    data.push(el.getAttribute("data-id"));
                });

                setselectedRowKeys(data)
            }}
            onScroll={e => {
                scrollerRef.current.scrollBy(e.direction[0] * 10, e.direction[1] * 10);
            }}
        />
    </React.Fragment>
}

export default withRouter(WidgetDeviceGridView);