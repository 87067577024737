import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form, Input, Button, Tag, Progress } from "antd";
import { AccountMetaData } from "../../../lib/account";
import { fetchData } from "../../../lib/helpers";

// //dummy props
// const props = {
//   nick: [{ uid: "huynv", _id: "badsadasjddjasgsjadjs" }],
//   visible: "...",
// };
// //end of dummy props

function checkNickInsta(data = {}) {
  let url = `${process.env.REACT_APP_API}/${data["url"]}`;
  let options = {
    method: data.method ? data.method : "GET",
  };
  if (data.body) options["body"] = data.body;
  options["headers"] = data["headers"] ? data["headers"] : {};

  let token = AccountMetaData.getToken();
  if (token) options["headers"]["Authorization"] = `JWT ${token}`;

  if (data["full_path"]) {
    url = data["full_path"];
  }
  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(url, options);
      response = await response.text();

      response = JSON.parse(response);
      if (response.code != 200 && response.code != 400) {
        console.log("reject 1, code: ", response.code);
        return reject(response);
      } else {
        return resolve(response);
      }
    } catch (err) {
      return reject({
        code: 500,
        mes: "err while checking insta",
        err: err,
      });
    }
  });
}

function chunk(items, size) {
  const chunks = [];
  items = [].concat(...items);

  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
}

const CheckLiveDieInstagram = (props) => {
  //url API
  const checkNickInstagram = "api/v1/instagram/checkuser";
  const updateNick = "api/v1/user_fb";
  //end of url API

  //state
  const [liveNum, setLiveNum] = useState(0);
  const [dieNum, setDieNum] = useState(0);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  //end of state

  //refs
  const formRef = useRef();
  //end of refs

  //functions
  const handleCheck = async (values) => {
    if (props.nicks.length > 0) {
      try {
        setLiveNum(0);
        setDieNum(0);
        setPercent(0);
        setIsBtnLoading(true);

        const nicks = props.nicks;
        const arrReqCheck = nicks.map((nick) => {
          let uid = nick.uid;
          let body = {};

          const cookieArr = values.cookie.trim().split("\n");
          const cookie =
            cookieArr[Math.floor(Math.random() * cookieArr.length)]; //pick a random cookie

          //apply values to body
          body.username = uid;
          body.cookie = cookie;

          //check live or die
          const res = checkNickInsta({
            url: `${checkNickInstagram}`,
            method: "POST",
            // body: JSON.stringify(body),
            body: new URLSearchParams(body),
            headers: {
              //  "Content-Type": "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          const _id = nick._id;

          //return both promise and _id
          return { res, _id };

          //update status nick
        });

        const arrToCheck = chunk(arrReqCheck, 50);

        let liveNum = 0;
        let dieNum = 0;
        let allRes = [];

        arrToCheck.forEach(async (arr) => {
          const resInit = await Promise.all(arr);
          // console.log(resInit); //for debug

          //check live/die
          const res = await Promise.all(resInit.map((rei) => rei.res));
          // console.log(res); //for debug

          //update status nick (now just based on index of _id array, because cant access res value of previous promise)
          const idArr = resInit.map((rei) => rei._id);
          await Promise.all(
            res.map((r, i) => {
              let body = {};
              if (r.code === 200) {
                body.status = 1;
              } else if (r.message === "User not found") {
                body.status = 4;
              }
              // body.device_id = "hieu dang test...";
              const nickId = idArr[i];
              return fetchData({
                method: "PUT",
                url: `${updateNick}/${nickId}`,
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/json" },
              });
            })
          );
          // console.log("resUpdate2: ", resUpdate2); //for debug

          allRes = [...allRes, ...res];
          liveNum = liveNum + res.filter((res) => res.code === 200).length;
          dieNum =
            dieNum +
            res.filter((res) => res.message === "User not found").length;

          // console.log(`done ${allRes.length} nick`); //for debug

          // message.success(`done ${allRes.length} nick`);
          //update states
          setPercent(Math.round((allRes.length / arrReqCheck.length) * 100));
          setLiveNum(liveNum);
          setDieNum(dieNum);

          if (arrReqCheck.length === allRes.length) {
            // message.success("hoàn tất check!");
            setIsBtnLoading(false);
          }
        });
      } catch (error) {
        setIsBtnLoading(false);
        message.error("gặp lỗi trong quá trình check nick Instagram!");
        console.log("error while check instagram: ", error);
      }
    } else {
      message.info("Vui lòng chọn ít nhất 1 tài khoản!");
    }
  };

  const handleCheckFailed = (error) => {
    console.log("submit check instagram form failded:", error);
    message.info("Vui lòng hoàn thành biểu mẫu trước khi check");
  };
  //end of functions

  //effects
  useEffect(() => {
    setPercent(0);
    setLiveNum(0);
    setDieNum(0);
  }, [props.nicks]);
  //end of effects

  //main render
  return (
    <Modal
      visible={props.visible}
      title="Kiểm tra nick sống/chết (Instagram)"
      footer={null}
      onCancel={props.onCancel}
      // width="80%"
    >
      <Form
        name="checkLiveDieInstagram"
        ref={formRef}
        autoComplete="off"
        // labelCol={{
        //   span: 6,
        // }}
        // wrapperCol={{
        //   span: 16,
        // }}
        onFinish={handleCheck}
        onFinishFailed={handleCheckFailed}
        layout="vertical"
      >
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Tag style={{ marginRight: "10px" }}>
            Tất cả: {props.nicks.length}
          </Tag>
          <Tag style={{ marginRight: "10px" }} color="green">
            Nick live: {liveNum}
          </Tag>
          <Tag style={{ marginRight: "10px" }} color="red">
            Nick die: {dieNum}
          </Tag>
        </div>

        <Progress percent={percent} style={{ marginBottom: "10px" }} />

        <Form.Item
          label="Cookie Instagram:"
          name="cookie"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập cookie!",
            },
          ]}
          extra="** Có thể nhập nhiều cookie để tránh bị limit **"
        >
          <Input.TextArea
            rows={3}
            placeholder="mỗi cookie ngăn cách nhau bởi dấu 'xuống dòng'"
            allowClear={true}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 20,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" loading={isBtnLoading}>
            Check
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CheckLiveDieInstagram;
