import { Form, DatePicker, Button, Input } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from 'dayjs';

const WidgetFormTimer = (props) => {
    const { remove, defaultValue, value } = props;

    // const [value, setValue] = useState(dayjs().add(1, "hour"))

    // useEffect(() => {
    //     if(onChange){
    //         onChange(value.startOf("minute").unix() * 1000);
    //     }
    // }, [ value ])

    useEffect(() => {
        if (defaultValue) {
            // setValue(dayjs(defaultValue))
        }
    }, [ defaultValue ])

    const onChange = (value) => {
        if(props.onChange){
            props.onChange(value.startOf("minute").unix() * 1000);
        }
    }

    return <>
        <div style={{
            display: "grid",
            gridTemplateColumns: "auto auto 50px",
            gap: "5px"
        }} className="mb-3">
            <div>
                <DatePicker
                    className="w-100"
                    disabledDate={(date) => date < moment().startOf("day")}
                    picker="date"
                    onChange={onChange}
                    allowClear={false}
                    value={dayjs(value)}
                />
            </div>
            <div>
                <DatePicker
                    picker="time"
                    className="w-100"
                    format={"HH:mm"}
                    allowClear={false}
                    onChange={onChange}
                    disabledDate={(date) => date < moment()}
                    value={dayjs(value)}
                />
            </div>
            <div>
                <Button onClick={remove} disabled={!remove} danger type="text"><i className="far fa-trash"></i></Button>
            </div>
        </div>
    </>
}

export default WidgetFormTimer;