import { ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, notification, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PopupMenu } from "../../component";
import { API_URL } from "../../lib/api";
import { getPosContextmenu } from "../../lib/app";
import { fetchData } from "../../lib/helpers";

import WidgetFormScript from "./Widget/form";
import WidgetFormScriptForAll from "./Widget/formScriptAll";

const DeviceView = (props) => {
    const { routerTypeAdmin } = props;

    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleFormScript, setvisibleFormScript] = useState({
        visible: false,
        item: null
    });
    const [visibleFormScriptForAll, setvisibleFormScriptForAll] = useState(false);

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            let response = await fetchData({
                url: routerTypeAdmin ? API_URL.admin.scriptContent.index : API_URL.scriptContent.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: routerTypeAdmin ? API_URL.admin.scriptContent.index : API_URL.scriptContent.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    useEffect(() => {
        getListData();
    }, [pagination]);

    return (
        <React.Fragment>
            <WidgetFormScript
                {...visibleFormScript}
                onCancel={() => setvisibleFormScript({ visible: false })}
                onFinish={() => {
                    setvisibleFormScript({ visible: false });
                    getListData();
                }}
                routerTypeAdmin={routerTypeAdmin}
            />

            {
                routerTypeAdmin && <WidgetFormScriptForAll
                    visible={visibleFormScriptForAll}
                    hide={() => setvisibleFormScriptForAll(false)}
                />
            }

            <div className="block">
                <div className="block-content block-content-m">
                    <h3 className="block-title mb-20">
                        {routerTypeAdmin ? "Quản lý script hệ thống" : "Quản lý script của bạn"}
                    </h3>
                    <div className="mb-20 d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={() => setvisibleFormScript({ visible: true })}
                        >
                            <i className="si si-plus mr-2"></i> Thêm mới
                        </Button>
                        {
                            routerTypeAdmin && <Button
                            type="primary"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={() => setvisibleFormScriptForAll(true)}
                        >
                            Script for all
                        </Button>
                        }
                        <Button
                            type="primary"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={() => deleteMany(selectedRowKeys) }
                            danger
                        >
                            <i className="si si-trash"></i>
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "#",
                                render: (value, record, index) => {
                                    return (
                                        pagination.limit * pagination.page -
                                        pagination.limit +
                                        index +
                                        1
                                    );
                                },
                            },
                            {
                                title: "Tiêu đề",
                                dataIndex: "title",
                            },
                            {
                                title: "Platform",
                                dataIndex: "platform",
                            },
                            {
                                title: "Nội dung",
                                dataIndex: "content",
                                render: (value) => {
                                    return <Input.TextArea value={value} rows={3}/>
                                }
                            },
                            {
                                title: "Updated",
                                dataIndex: "updated_time",
                                render: (value) => moment(value).format("HH:mm DD/MM/YYYY")
                            },
                            {
                                title: "Created",
                                dataIndex: "created_time",
                                render: (value) => moment(value).format("HH:mm DD/MM/YYYY")
                            },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <Button type="primary" size="small" 
                                            onClick={() => setvisibleFormScript({ visible: true, item: record })}
                                            className="ml-2"
                                        >
                                            <i className="far fa-edit"></i>
                                        </Button>
                                        <Button type="primary" size="small" danger 
                                            onClick={() => deleteMany([record._id])}
                                            className="ml-2"
                                        >
                                            <i className="far fa-trash"></i>
                                        </Button>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                        }}
                        loading={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default DeviceView;
