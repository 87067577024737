import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, TreeSelect} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class FormMoveDataToFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });
            let data = {
                to_folder_id: values['folder_id'],
                action: 2,
                list_ids: this.props.listIdChecked
            };

            let result = await this.props.fetchData({
                url: 'api/v1/data/move',
                method: 'post',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            });

            this.setState({ loading: false }, () => {
                this.props.resetData();
                this.props.hide();
                this.props.notification({
                    message: 'Di chuyển dữ liệu',
                    description: `Di chuyển thành công ${result.data} tài khoản`,
                    type: 'success'
                })
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    message: 'Di chuyển dữ liệu',
                    description: 'Di chuyển dữ liệu không thành công ' + err.message,
                    type: 'error'
                })
            });
        }
    };
    cancel = () => {
        this.form.resetFields();
        this.props.hide();
    }
    render() {
        return (
            <Modal
                title={`Chuyển dữ liệu (${this.props.listIdChecked.length})`}
                visible={this.props.visible}
                onCancel={this.cancel}
                footer={null}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    layout="vertical"
                >
                    <Form.Item
                            label="Chọn nhóm tài khoản"
                            name="folder_id"
                            rules={[{ required: true, message: 'Bạn phải chọn nhóm!' }]}
                        >

                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={this.props.listFolder ? this.props.listFolder : []}
                                placeholder="Tìm tên hoặc id nhóm"
                                treeDefaultExpandAll={false}
                                allowClear
                                showSearch
                                disabled={this.state.loading}
                                filterOption={(input, option) =>  {
                                    if(option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value == input){
                                        return option
                                    }
                                }}
                            />
                        </Form.Item>
                    <div className="text-center">
                        <Button type="primary" loading={this.state.loading} htmlType="submit" >
                            Xác nhận
                            </Button>
                    </div>
                </Form>
            </Modal>
        )
    }
}
