import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Modal, Form, Input, Button, InputNumber} from 'antd';
import { AccountMetaData, setCookie} from '../../lib/account';
import moment from 'moment';

import Version from "../../../package.json";
import { Config } from '../../utils/config';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }
    componentDidMount = () => {
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_show') {
                    document.querySelector('#page-container').classList.add('sidebar-o-xs');
                } else {
                    console.log(action);
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
            })
        })
        let _this = this;
        document.querySelector('#page-header-notifications').addEventListener("click", function (e){
            _this.seenNotify();
        })
    }
    onSave = async (values) => {
        try {
            this.setState({ loading: true });
            console.log('Values form', values);
            let resutl = await this.props.fetchData({
                url: 'api/v1/user/change_pw',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            
            setCookie(AccountMetaData.token_name, btoa(resutl['data']['token']), 2);
            if(document.querySelector('#access_token')) document.querySelector('#access_token').value = resutl['data']['token'];
            this.setState({ loading: false, visible: false }, () => {
                this.form.resetFields();
                this.props.notification({
                    type: 'success',
                    message: 'Đổi mật khẩu',
                    description: 'Thao tác thành công'
                })
                if(this.props.getNewToken) this.props.getNewToken();
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.message({ 
                    content: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error'
                })
            });
        }
    }
    onSaveTokenMcare = async (values) => {
        try {
            this.setState({ loading: true });
            let resutl = await this.props.fetchData({
                url: 'api/v1/user_fb/sync_us/save_token',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false })
            let user = this.props.userSystem;
            user['token_us'] = values['token_us'];

            this.props.updateUserSystem(user);
            this.props.notification({
                type: 'success',
                message: 'Cập nhập token us',
                description: 'Thao tác thành công'
            })
        } catch(err){
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    message: 'Cập nhập token us',
                    description: err.message,
                    type: 'error'
                })
            });
        }
    }
    onSaveOption = async (values) => {
        try {
            this.setState({ loading: true });
            let resutl = await this.props.fetchData({
                url: 'api/v1/user/options',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false })
            this.props.notification({
                type: 'success',
                message: 'Thiết lập nâng công',
                description: 'Thao tác thành công'
            })
        } catch(err){
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    message: 'Thiết lập nâng công',
                    description: err.message,
                    type: 'error'
                })
            });
        }
    }
    seenNotify = async () => {
        try {
            console.log(this.props.dataNotify);
            localStorage.setItem('count_seen', this.props.dataNotify.count_unseen);
            this.setState({
                reload: !this.state.reload
            })
            return;
            let result = await this.props.fetchData({
                url: 'api/v1/notifications/seen',
                method: 'get'
            })
            if(this.props.getNotify) this.props.getNotify();
            console.log(result);
        } catch(err){
            console.log(err);
        }
    }
    render() {
        let count_seen_noti = this.props.dataNotify && this.props.dataNotify.count_unseen ? this.props.dataNotify.count_unseen : 0;
        let count_seen_local = localStorage.getItem('count_seen') ? parseInt(localStorage.getItem('count_seen')) : 0;
        return (
            <header id="page-header">
                <Modal
                    title="Đổi mật khẩu"
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    footer={null}
                >
                    <Form
                        onFinish={this.onSave}
                        ref={(evt) => this.form = evt}
                        layout="vertical"
                    >
                        <Form.Item
                            name="password_old"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu cũ!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu cũ" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu mới!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu mới" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Không trùng khớp');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Nhập lại mật khẩu" />
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loading} htmlType="submit">
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title="Access token của bạn"
                    visible={this.state.visibleToken}
                    onCancel={() => this.setState({ visibleToken: false })}
                    footer={null}
                >
                    <textarea className="form-control" style={{height: "200px"}} id="access_token">{AccountMetaData.getToken()}</textarea>
                </Modal>
                <Modal
                    title="Cập nhập Token Us"
                    visible={this.state.visibleTokenMcare}
                    onCancel={() => this.setState({ visibleTokenMcare: false })}
                    footer={null}
                >
                    <Form
                        onFinish={this.onSaveTokenMcare}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            token_us: this.props.userSystem.token_us,
                            script_fb: this.props.userSystem.script_fb
                        }}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Token"
                            name="token_us"
                        >
                            <Input.TextArea placeholder="Nhập token.." />
                        </Form.Item>
                        <Form.Item
                            label="Script"
                            name="script_fb"
                        >
                            <Input.TextArea placeholder="" style={{minHeight: "300px"}}/>
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loading} htmlType="submit">
                                Lưu
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title="Thiết lập nâng cao"
                    visible={this.state.visibleOption}
                    onCancel={() => this.setState({ visibleOption: false })}
                    footer={null}
                >
                    <Form
                        onFinish={this.onSaveOption}
                        initialValues={{
                            ...(this.props.userSystem.options || {})
                        }}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Thời gian online của thiết bị"
                            name="device_time_online"
                        >
                            <InputNumber 
                                addonBefore="<="
                                addonAfter="Phút" 
                                className="w-100"
                            />
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loading} htmlType="submit">
                                Lưu
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="content-header">
                    <div className="content-header-section">
                        <button type="button" className="btn btn-circle btn-dual-secondary" data-toggle="sidebar" data-action="sidebar_show">
                            <i className="fa fa-navicon"></i>
                        </button>
                    </div>
                    <div className="content-header-section">
                        <div className='btn-group rounded bg-primary text-white pl-2 pr-2'>
                            CMS {Version.version}
                        </div>
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-rounded btn-dual-secondary" id="page-header-user-dropdown"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-user d-sm-none"></i>
                                <span className="d-none d-sm-inline-block">{this.props.userSystem.full_name} ({Config.levelUser[this.props.userSystem.level]})</span>
                                <input type="hidden" name="user_id_login" value="{{$user_login->user_id}}" />
                                <input type="hidden" name="user_login" value="{{json_encode($user_login)}}" />
                                <i className="fa fa-angle-down ml-5"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right min-width-200"
                                aria-labelledby="page-header-user-dropdown">
                                <h5 className="h6 text-center py-10 mb-5 border-b text-uppercase">Tài khoản</h5>
                                {/* <a className="dropdown-item" href="{{route('me.form.update')}}">
                                    <i className="si si-user mr-5"></i> Cập nhập thông tin
                                </a> */}
                                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.setState({ visible: true })}>
                                    <i className="si si-lock mr-5"></i> Đổi mật khẩu
                                </a>
                                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.setState({ visibleToken: true })}>
                                    <i className="si si-user mr-5"></i> Access token
                                </a>
                                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.setState({ visibleOption: true })}>
                                    <i className="si si-settings mr-5"></i> Thiết lập nâng cao
                                </a>
                                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.setState({ visibleTokenMcare: true })}>
                                    <i className="si si-share mr-5"></i> Cài đặt
                                </a>
                                <a href="javascript:void(0)" className="dropdown-item" onClick={() => this.props.logOut()}>
                                    <i className="si si-logout mr-5"></i> Đăng xuất
                                 </a>
                            </div>
                        </div>
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-rounded btn-dual-secondary" id="page-header-notifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bell"></i>
                                {
                                    count_seen_noti > count_seen_local ? <span class="badge badge-primary badge-pill num-noti">
                                    { (count_seen_noti - count_seen_local) > 9 ? '9+' : (count_seen_noti - count_seen_local)}
                                </span> : null
                                }
                            </button>
                            <div class="dropdown-menu dropdown-menu-right min-width-300" aria-labelledby="page-header-notifications">
                                <h5 class="h6 text-center py-10 mb-0 border-b text-uppercase">Thông báo</h5>
                                {
                                    this.props.dataNotify.data ? 
                                    <ul class="list-unstyled my-20">
                                        {
                                            this.props.dataNotify.data.map((item, i) => {
                                                return <li key={i}>
                                                    <a class="text-body-color-dark media mb-15">
                                                        <div class="ml-5 mr-15">
                                                            { item.type == 1 ? 
                                                            <i class="fa fa-fw fa-plus text-primary"></i> : 
                                                            <i class="fa fa-fw fa-check text-success"></i>}
                                                        </div>
                                                        <div class="media-body pr-10">
                                                            <p class="mb-0">{item.title}</p>
                                                            <div class="text-muted font-size-sm font-italic">{item.content}</div>
                                                            <div class="text-muted font-size-sm font-italic">- {moment(item.time_created).fromNow()}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                     : null
                                }
                                {
                                    this.props.dataNotify.data && this.props.dataNotify.data.length > 0 ?
                                    <div class="dropdown-divider"></div> : null
                                }
                                {
                                    this.props.dataNotify.data && this.props.dataNotify.data.length > 0 ?
                                    <a class="dropdown-item text-center mb-0">
                                        {/* <i class="fa fa-flag mr-5"></i> Xem tất cả */}
                                    </a> : <div className="text-center p-10">Bạn chưa có thông báo nào</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
