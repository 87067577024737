import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, TreeSelect, Select, notification } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { API_URL } from '../../lib/api';
import { fetchData } from '../../lib/helpers';
import { ListFieldsUid } from '../../utils/config';

const UploadUserFb = (props) => {
    const [fileList, setfileList] = useState([]);
    const [file, setfile] = useState(null);
    const [loading, setloading] = useState(false);
    const [listDevice, setlistDevice] = useState([]);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            if(!values.is_policy) throw({ message: "Bạn phải đồng ý với điều khoản dịch vụ của chúng tôi!" })
            setloading(true);
            console.log('Values form', values);
            if (!values.upload) {
                props.notification({
                    message: 'Lỗi upload',
                    description: 'Bạn chưa chọn file!',
                    type: 'error'
                })
                return;
            }
            let formData = new FormData();
            formData.append('file', values.upload.file);
            formData.append('format', values.format);
            formData.append('folder_id', props.currentFolder ? parseInt(props.currentFolder) : 0);
            formData.append("device_ids", values.device_ids)
            let resutl = await fetchData({
                url: 'api/v1/user_fb/create_many',
                method: 'post',
                body: formData,
                notAddContentType: true
            });
            if (props.success) props.success({
                doc: resutl.doc,
                device_ids: values.device_ids || []
            });
            notification.success({
                message: "Thông báo",
                description: `Cập nhập thành công ${resutl.doc.nUpserted} đối tượng mới, và ${resutl.doc.nMatched} cũ`
            })
        } catch (err) {
            console.log(err);
            notification.error({
                message: "Thông báo",
                description: err.message || 'Đã có lỗi xảy ra'
            })
        } finally {
            setloading(false);
        }
    };
    const onFileChange = ({ fileList }) => {
        setfileList([])
        setfile(fileList[0]);
    }

    useEffect(() => {
        if (!props.device_ids) {
            getListDevice();
        }
    }, [])

    const getListDevice = async () => {
        try {
            let response = await fetchData({
                url: API_URL.appProfile.listAll
            });
            setlistDevice(response.data);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || 'Đã có lỗi xảy ra'
            })
        } finally {
        }
    };

    return (
        <Modal
            title="Upload"
            visible={props.visible}
            onCancel={props.hide}
            footer={null}
            className={'update-order-tool'}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={form}
                initialValues={{
                    format: ["uid", "password", "secret_2fa", "email", "password_email"],
                    format_text: "uid | password | secret_2fa | email | password_email",
                    device_ids: props.device_ids || []
                }}
            >
                <Form.Item name="upload" >
                    <Upload.Dragger name="files" beforeUpload={() => false} accept={'.txt'} fileList={fileList} onChange={onFileChange} showUploadList={false}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">{file ? file.name : 'Support for a single or bulk upload.'}</p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item name="format">
                    <Checkbox.Group style={{ width: "100%" }} onChange={(e) => { form.current.setFieldsValue({ format_text: e.join(' | ') }) }}>
                        <Row>
                            {
                                ListFieldsUid.map((item, key) => {
                                    return <Col span={8} key={key}>
                                        <Checkbox value={item.value} style={{ lineHeight: '32px', whiteSpace: "nowrap" }}>
                                            {item.label}
                                        </Checkbox>
                                    </Col>
                                })
                            }

                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item
                    name="format_text"
                >
                    <Input disabled={true} />
                </Form.Item>

                {
                    props.device_ids ?
                        <>
                            <p>Số thiết bị đã chọn: {props.device_ids.length}</p>
                            <Form.Item
                                label="Device ID mới:"
                                name="device_ids"
                                extra="( Để trống nếu không muốn gán Device cho nick )"
                                noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>
                        </>
                        :
                        <Form.Item
                            label="Device ID mới:"
                            name="device_ids"
                            extra="( Để trống nếu không muốn gán Device cho nick )"
                        >
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                                treeData={listDevice.map((item) => {
                                    return {
                                        title: item._id || "Other",
                                        value: `parent_${item._id || "other"}`,
                                        selectable: false,
                                        children: item.data.map((item_) => {
                                            return {
                                                title: item_.name || item_.profile_id,
                                                value: item_.profile_id
                                            }
                                        }),
                                    }
                                })}
                                placeholder="Chọn thiết bị"
                                allowClear
                                showArrow
                                showSearch
                                multiple={true}
                                treeCheckable
                            />
                        </Form.Item>
                }
                <div className="d-flex justify-content-between">
                    <Form.Item name={"is_policy"} valuePropName="checked">
                        <Checkbox >
                            <a href="/dieu-khoan-su-dung.html" target='_blank'>Tôi đồng ý với điều khoản dịch vụ</a>
                        </Checkbox>
                    </Form.Item>
                    <Button type="primary" loading={loading} htmlType="submit" disabled={!file ? true : false}>
                        Tiếp tục
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default UploadUserFb;