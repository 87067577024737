import { Modal, Form, Button, Input, Select, notification, Radio } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";

const FormScript = (props) => {
    const { roleTypeAdmin } = props;

    const [loading, setloading] = useState(false);
    const [loadingGetScript, setloadingGetScript] = useState(false);
    const [ listScript, setlistScript ] = useState([]);
    const [ createType, setcreateType ] = useState(1);

    const form = useRef();
    const timeoutRed = useRef();

    const onFinish = async (values) => {
        try{
            setloading(true);
            console.log(values);
            await fetchData({
                url: roleTypeAdmin ? API_URL.admin.appProfile.index : API_URL.appProfile.index,
                method: "put",
                body: JSON.stringify({
                    "ids": props.devices,
                    "extra_data.content_script": values.content_script || "",
                    "extra_data.id_script": values.id_script,
                    update_type: {
                        type: createType,
                        device_group: values.device_group
                    }
                })
            });
            props.onCancel();
            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công"
            })
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally{
            setloading(false);
        }
    }

    const getListScript = async (values) => {
        try{
            setloadingGetScript(true);
            let params = {
                type: "public"
            };

            if(values) params = {...params, ...values };

            let response = await fetchData({
                url: API_URL.scriptContent.index,
                params: params
            });

            setlistScript(response.data);
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally{
            setloadingGetScript(false);
        }
    }

    useEffect(() => {
        if(!props.visible && form.current){
            form.current.resetFields();
        }
        if(props.visible){
            getListScript();
            if(props.item){
                form.current.setFieldsValue({
                    content_script: props.item.extra_data && props.item.extra_data.content_script,
                    id_script: props.item.extra_data && props.item.extra_data.id_script,
                })
            }
        }
    }, [props.visible])

    return (
        <Modal
            title="Thêm script cho thiết bị"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
            width={600}
        >
            <p>Tổng số thiết bị: {props.devices.length}</p>
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={form}
            >
                <Form.Item >
                    <Radio.Group value={createType} onChange={(e) => setcreateType(e.target.value)}>
                        <Radio value={1}>Thiết bị đã chọn ({props.devices.length})</Radio>
                        <Radio value={2}>Thiết bị cùng box</Radio>
                        <Radio value={3}>Tất cả thiết bị ({props.totalDevice})</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    createType == 2 && <Form.Item 
                        rules={[
                            {
                                required: true,
                                message: "Không được để trống"
                            }
                        ]}
                        label="Chọn box name" name="device_group" >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Box name"
                                options={props.optionBoxName || []}
                                showSearch
                                allowClear
                                mode="multiple"
                            />
                    </Form.Item>
                }
                <Form.Item label="Chọn script có sẵn" name="id_script">
                    <Select 
                        showArrow 
                        showSearch 
                        onSearch={(e) => {
                            if(timeoutRed.current) clearTimeout(timeoutRed.current);

                            timeoutRed.current = setTimeout(() => {
                                getListScript({ keyword: e })
                            }, 300)
                        }} 
                        loading={loadingGetScript}
                        allowClear
                        filterOption={false}
                        onChange={(e) => {
                            let find = listScript.find((item) => item._id == e);
                            form.current.setFieldsValue({
                                content_script: find ? find.content : "",
                                id_script: find ? find._id : ""
                            })
                        }}
                    >
                        {
                            listScript.map((item) => {
                                return <Select.Option value={item._id}>{item.title}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                {/* <Form.Item label="Nội dung script" name="content_script"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Input.TextArea style={{ minHeight: "160px"}}
                        placeholder="Nhập nội dung.."
                    />
                </Form.Item> */}
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>Cập nhập</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default FormScript;