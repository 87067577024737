import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Alert} from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class AlertStopCheckpoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    render() {
        return (
            <Modal
            title={null}
            visible={this.props.visible}
            onCancel={this.props.hide}
            footer={null}
            centered
            >
                <div className="p-50 pl-10 pr-20">
                    <Alert
                    message="Thông báo"
                    description={this.props.title || "Tiến trình get cookie tạm dừng vì đạt giới hạn số lượng checkpoint."}
                    type="info"
                    showIcon
                    style={{fontSize: "16px"}}
                    />
                </div>

            </Modal>
        )
    }
}
