import React, { Component, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, notification } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { fetchData } from '../../../lib/helpers';
import moment from 'moment';
import { ListFieldsUid } from '../../../utils/config';

const WidgetShareData = (props) => {
    const { visible, onCancel, ids } = props;
    const refForm = useRef();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            setLoading(true);

            let data = {
                user_name: values.user_name,
                list_ids: ids,
            }

            data['options'] = {
                expired_time: moment().add(values.time, "day").unix() * 1000,
                fields: values.fields || []
            }

            await fetchData({
                url: 'api/v1/user/share/data',
                method: 'post',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            });

            notification.success({
                description: 'Thao tác thành công',
                message: 'Chia sẻ tài nguyên'
            })

        } catch (err) {
            notification.error({
                description: err.message || 'Đã có lỗi xảy ra',
                message: 'Chia sẻ tài nguyên'
            })
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={`Chia sẻ tài nguyên`}
            visible={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                ref={refForm}
                layout="vertical"
                initialValues={{
                    time: 1
                }}
            >
                <p>Tài nguyên chia sẻ: {ids.length}</p>
                <div className='row'>
                    <Form.Item
                        className='col-6'
                        name="user_name"
                        label="Nguời nhận"
                        rules={[{ required: true, message: 'Bạn phải nhập username đăng nhập của người nhận!' }]}
                    >
                        <Input placeholder="Nhập username.." />
                    </Form.Item>

                    <Form.Item
                        className='col-6'
                        name="time"
                        label="Thời hạn"
                    >
                        <InputNumber className='w-100' addonAfter="Ngày" />
                    </Form.Item>
                </div>

                <Form.Item label="Ẩn thông tin" name="fields" extra={"*Nếu không chọn: người dùng sẽ nhìn được toàn bộ các thông tin tài nguyên được chia sẻ."}>
                    <Checkbox.Group style={{ width: "100%" }}>
                        <Row>
                            {
                                ListFieldsUid.map((item) => {
                                    return <Col span={8}>
                                        <Checkbox value={item.value} style={{ lineHeight: '32px' }}>
                                            {item.label}
                                        </Checkbox>
                                    </Col>
                                })
                            }
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <div className="text-right">
                    <Button type="primary" loading={loading} htmlType="submit" >
                        Xác nhận
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default WidgetShareData;