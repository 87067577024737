import { Modal, Form, Button, Input, Select, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";

const FormUpdate = (props) => {
    const [loading, setloading] = useState(false);

    const form = useRef();

    const onFinish = async (values) => {
        try{
            setloading(true);

            await fetchData({
                url: API_URL.appProfile.index+'/'+values._id,
                method: "put",
                body: JSON.stringify(values)
            });
            
            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công"
            })
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally{
            setloading(false);
        }
    }

    useEffect(() => {
        if(!props.visible && form.current){
            form.current.resetFields();
        }
        if(props.visible){
            form.current.setFieldsValue(props.item)
        }
    }, [props.visible])

    return (
        <Modal
            title="Cập nhập device"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={form}
            >
                <Form.Item name={"_id"} noStyle>
                    <Input type={"hidden"} />
                </Form.Item>
                <Form.Item name="name" label="Tên device"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="device_group" label="Device group">
                    <Input />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>Cập nhập</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default FormUpdate;