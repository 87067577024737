import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Modal, Upload, message, Tooltip } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { fetchData } from "../../../lib/helpers";
import { AccountMetaData } from "../../../lib/account";

const DetailContentModal = (props) => {
  const data = props.data;
  //url API
  const uploads = "api/v1/uploads";
  const update = "api/v1/post/";
  //end of url API

  //states
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [listFile, setListFile] = useState([]);
  //end of states

  //refs
  const formRef = useRef();
  //end of refs

  //functions
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const propsUploadImage = {
    name: "file",
    accept: "image/*",
    action: `${process.env.REACT_APP_API}/${uploads}`,
    headers: {
      authorization: `JWT ${AccountMetaData.getToken()}`,
    },
    listType: "picture-card",

    onChange: (info) => {
      // if (info.file.status !== "uploading") {
      //   // console.log(info.file, info.fileList);
      // }
      // if (info.file.status === "done") {
      //   setListFile(info.fileList); //update file list
      //   message.success(`${info.file.name} file uploaded successfully`);
      //   console.log("success", info); //for debug
      // } else if (info.file.status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
      if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        return;
      }
      setListFile(info.fileList); //update file list
      // console.log("info", info); //for debug
    },

    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    },
    fileList: listFile,
  };

  //this fuction only use for img/txt (content) links when uploads, it returns a string of links, separated by ","
  function handleContentLinks(obj) {
    let arrResult = [];
    obj.fileList.forEach((fl) => {
      if (!fl.lastModified) {
        arrResult.push(fl.url);
      } else {
        arrResult.push(fl.response.attachment.url);
      }
    });
    // console.log(arrResult); //for debug
    // return arrResult.join(","); // for mobile to read
    return arrResult; //for webview
  }

  const onFinish = async (values) => {
    try {
      setIsBtnLoading(true);

      const obj = { ...values };
      // console.log("obj: ", obj); //for debug

      let body = {};
      // let attachment = [];
      // let folder_id;

      //folder_id
      // selectedFolder ? (folder_id = selectedFolder.node.id) : (folder_id = 0);

      //attachment

      //update body values
      body.title = obj.contentName;
      // body.folder_id = folder_id;
      body.content = obj.postContent;
      if (obj.postImage) {
        // console.log(obj.postImage); //for debug
        body.attachment = handleContentLinks(obj.postImage);
      }

      await fetchData({
        method: "PUT",
        url: update + data.key,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      });
      setIsBtnLoading(false);
      setIsBtnDisable(true);
      props.reload();
      props.onCancel();
      message.success("Cập nhật thành công!");
    } catch (error) {
      setIsBtnLoading(false);
      console.log("error while create new content: ", error);
      message.error("Gặp lỗi trong quá trình tạo content!");
      // setImageNum(0);
      // setIsAddContentBtnLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed Submit:", errorInfo);
    message.error("Vui lòng hoàn thành biểu mẫu trước khi tạo!");
  };
  //end of functions

  //effects
  useEffect(() => {
    // console.log("data for detail modal:", props.data);
    if (props.data.content_name) {
      //handle list image files
      const imageUrl = data.postImage;
      if (imageUrl) {
        const imageUrlArr = imageUrl.split("\n");
        let listFileArr = [];
        imageUrlArr.forEach((url, index) => {
          listFileArr.push({
            uid: index,
            name: url.split("-")[1],
            url: url,
            status: "done",
          });
        });
        // console.log("listFileArr: ", listFileArr); //for debug
        setListFile(listFileArr);
      } else {
        setListFile([]);
      }
      //end of handle list image files
      setIsBtnDisable(true);

      formRef.current.setFieldsValue({
        contentName: data.content_name,
        postContent: data.postContent,
      });
    }
  }, [props.data, props.visible]);
  //end of effects
  return (
    <Modal
      footer={null}
      visible={props.visible}
      title="Chi tiết Content"
      onCancel={props.onCancel}
      width="60%"
    >
      <Form
        name="detail_content"
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        onFieldsChange={(changedFields, allFields) => {
          setIsBtnDisable(false);
        }}
        layout="vertical"
      >
        <Form.Item
          label="Tên content:"
          name="contentName"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên content mới!",
            },
          ]}
        >
          <Input allowClear={true} placeholder="Nhập tên Content mới" />
        </Form.Item>

        <Form.Item
          label="Nội dung bài đăng"
          // extra="file dạng '.txt'"
          name="postContent"
          rules={[
            {
              required: true,
              message: "Vui lòng tải lên nội dung post!",
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="..."></Input.TextArea>
        </Form.Item>

        <Form.Item
          label="Ảnh trong bài đăng"
          name="postImage"
          extra="Có thể upload nhiều ảnh"
        >
          <Upload {...propsUploadImage}>
            {listFile.length >= 5 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>

        <Form.Item
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Cập nhật thay đổi content">
              <Button
                type="primary"
                htmlType="submit"
                loading={isBtnLoading}
                disabled={isBtnDisable}
              >
                Cập nhật
              </Button>
            </Tooltip>

            <Tooltip title="Giữ nguyên nội dung cũ và thoát">
              <Button
                type="primary"
                onClick={() => {
                  props.onCancel();
                }}
                style={{ marginLeft: "10px" }}
                danger
              >
                Hủy bỏ
              </Button>
            </Tooltip>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DetailContentModal;
