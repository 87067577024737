import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form, Input, Button, Tag, Progress, Select, notification, TreeSelect } from "antd";
import { fetchData } from "../../../lib/helpers";
import { API_URL } from "../../../lib/api";

const ChangeDeviceId = (props) => {
  const [percent, setPercent] = useState(0);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [loadingGetDevice, setloadingGetDevice] = useState(false);
  const [listDevice, setlistDevice] = useState([]);


  //refs
  const formRef = useRef();
  const timeoutRef = useRef();

  const onFinish = async (values) => {
    if (props._id.length > 0) {
      try {

        setIsBtnLoading(true);
        setPercent(0);
        let body = {};

        let device_ids = values.device_ids ? values.device_ids : [];

        let index_device = 0;

        body.data = props._id.map((item) => {
          index_device++;
          if(!device_ids[index_device]) index_device = 0;
          return {
            _id: item,
            device_id: device_ids.length ? device_ids[index_device] : ""
          }
        });

        await fetchData({
          method: "POST",
          url: API_URL.account.updateMany,
          body: JSON.stringify(body),
          headers: { "Content-Type": "application/json" },
        });

        setPercent(100);
        setIsBtnLoading(false);
      } catch (error) {
        setIsBtnLoading(false);
        console.log(error);
        message.error("Gặp lỗi trong quá trình change Device_ID");
      }
    } else {
      message.info("Vui lòng chọn ít nhất 1 tài khoản!");
    }
  };

  const onFinishFailed = (err) => {
    message.info("vui lòng hoàn thành biểu mẫu trước khi gửi!");
    console.log("error before submiting form: ", err);
  };

  const getListDevice = async () => {
    try {
      setloadingGetDevice(true);
      let response = await fetchData({
        url: API_URL.appProfile.listAll
      });
      setlistDevice(response.data);
    } catch (err) {
      notification.error({
        message: "Thông báo",
        description: err.message
      });
    } finally {
      setloadingGetDevice(false);
    }
  };

  //end of functions

  //effects
  useEffect(() => {
    // setDoneNum(0);
    setPercent(0);
  }, [props._id]);
  //end of effects

  useEffect(() => {
    getListDevice();
  }, [])

  useEffect(() => {
    if(props.visible){
      setPercent(0);
    }
  }, [props.visible])

  //main render
  return (
    <Modal
      visible={props.visible}
      title="Cập nhật Device ID"
      footer={null}
      onCancel={props.onCancel}
    >
      <Form
        name="changeDeviceId"
        ref={formRef}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            type="default"
            size="medium"
            shape="round"
            style={{ marginRight: "10px" }}
          >
            Tất cả: {props._id.length}
          </Button>
          {/* <Button type="default" size="medium" shape="round">
            Đã update: {doneNum}
          </Button> */}
        </div>

        <Progress percent={percent} style={{ marginBottom: "10px" }} />

        <Form.Item
          label="Device ID mới:"
          name="device_ids"
          extra="( Để trống nếu không muốn gán Device cho nick )"
        >
           <TreeSelect
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
            treeData={listDevice.map((item) => {
              return {
                title: item._id || "Other",
                value: `parent_${item._id || "other"}`,
                selectable: false,
                children: item.data.map((item_) => {
                  return {
                    title: item_.name || item_.profile_id,
                    value: item_.profile_id
                  }
                }),
              }
            })}
            placeholder="Chọn thiết bị"
            allowClear
            showArrow
            showSearch
            multiple={true}
            treeCheckable
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 20,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" loading={isBtnLoading}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeDeviceId;
