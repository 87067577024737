import { Modal } from "antd";
import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import WidgetFormCopyPost from "../../CopyPost/Widget/form";

const WidgetFormCopyPostUid = (props) => {

    return <WidgetFormCopyPost
        {...props}
        onFinish={(response) => {
            props.hide();
            Modal.success({
                title: "Thông báo",
                content: "Thao tác thành công!",
                onOk: () => {
                    props.history.push(`/copy_post`)
                },
                okText: "Quản lý đăng lại",
                okCancel: true,
                cancelText: "Đóng"
            })
        }}
    />
}

export default withRouter(WidgetFormCopyPostUid);