import { ROOT_ACTION } from "../../contants";

const INITIAL_STATE = {
    Platform: {
        data: [],
        actions: []
    }
}

const AccessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROOT_ACTION.platform.action.get:
            return state
        case ROOT_ACTION.platform.action.getSuccess:
            return {
                ...state,
                Platform: {
                    data: action.data.platform,
                    actions: action.data.data
                }
            };
        case ROOT_ACTION.platform.updateSate:
            console.log(action);
            return {
                ...state,
                Platform: {
                    ...state.Platform,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default AccessReducer;