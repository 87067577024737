import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber } from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class FormProxy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });
            let resutl = await this.props.fetchData({
                url: 'api/v1/proxy/save',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false });
            this.form.resetFields();
            this.props.success(resutl.doc);

        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    content: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error'
                })
            });
        }
    };
    cancel = () => {
        this.form.resetFields();
        this.props.hide();
    }
    render() {
        return (
            <Modal
            title="Cập nhập proxy"
            visible={this.props.visible}
            onCancel={this.cancel}
            footer={null}
            >
                <Form
                        onFinish={this.onFinish}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            time_get_tinsoft: this.props.proxyTinsoft && this.props.proxyTinsoft.option ? this.props.proxyTinsoft.option.time_get : 120,
                            key_tinsoft: this.props.proxyTinsoft && this.props.proxyTinsoft.option ? this.props.proxyTinsoft.option.key : ''
                        }}
                        layout="vertical"
                    >
                        <Form.Item 
                            name="proxies"
                            label="Proxy mới"
                            className="mb-0"
                        >
                            <Input.TextArea placeholder="Ip:Port" style={{height: "150px"}}/>
                        </Form.Item>
                        <Form.Item>
                            <span className="form-text text-muted">Chú ý: 
                                <p className="mb-0">- Mỗi dòng là 1 proxy.</p>
                                <p className="mb-0">- Loại proxy này chưa được dùng.</p>
                            </span>
                        </Form.Item>
                        <Form.Item 
                            name="key_tinsoft"
                            label="Key tinsoft"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            name="time_get_tinsoft"
                            label="Time get (giây)"
                            className="mb-0"
                        >
                            <InputNumber min={1} style={{width: "100%"}}/>
                        </Form.Item>
                        <Form.Item>
                            <span className="form-text text-muted">Là thời gian lấy proxy mới từ tinsoft</span>
                        </Form.Item>

                        <div className="text-center">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
            </Modal>
        )
    }
}
