import { Button, Form, Input, message, Modal, notification, Radio, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "../../../../lib/helpers";
import { API_URL } from "../../../../lib/api";
import { updateStatePlatform } from "../../../../redux/actions/access";

const WidgetFormPlatform = (props) => {
    const { visible, hide, Access, updateStatePlatform } = props;
    const [loading, setloading] = useState(false);

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);
            let response = await fetchData({
                url: API_URL.admin.platform.index,
                method: "post",
                body: JSON.stringify(values)
            });
            if(updateStatePlatform){
                updateStatePlatform({
                    data: response.data
                })
            }
            hide();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (visible) {
            formRef.current.setFieldsValue({
                platform: Access.Platform.data
            })
        }

    }, [visible])

    return (
        <Modal
            title="Cập nhập platform"
            visible={visible}
            onCancel={hide}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={formRef}
            >
                <Form.Item name="platform" label="Platform">
                    <Select mode="tags"></Select>
                </Form.Item>
                <Form.Item className="d-flex mb-0 justify-content-end">
                    <Button onClick={hide} className="mr-2">
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({
    updateStatePlatform: value => dispatch(updateStatePlatform(value))
})

export default connect(mapStateToProps, mapDispathToProps)(WidgetFormPlatform);
