import { Button, Form, Input, message, Modal, notification, Radio, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import WidgetFormRender from "../../../Action/Task/Widget/FormItem/FormRender";

import { fetchData } from "../../../../lib/helpers";
import { API_URL } from "../../../../lib/api";
import WidgetInputCodeMirror from "./inputCodeMirror";
import { Config } from "../../../../utils/config";

const WidgetFormData = (props) => {
    const { visible, hide, item, platform } = props;

    const [dataForm, setDataForm] = useState({ fields: [] });
    const [dataString, setdataString] = useState("");
    const [inputType, setinputType] = useState("json");
    const [loading, setloading] = useState(false);

    const formRef = useRef();
    const viewEditor = useRef();
    const formPreview = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);
            let fields = checkValueJson(viewEditor.current.state.doc.toString());
            let response = await fetchData({
                url: API_URL.admin.platform.action.index,
                method: "post",
                body: JSON.stringify({
                    data: [
                        {
                            ...values,
                            fields: fields
                        }
                    ]
                })
            });
            notification.success({
                message: "Thông báo",
                description: `Thao tác thành công!`
            });
            // hide();
            props.onFinish();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const checkValueJson = (values) => {
        try{
            let json = JSON.parse(values);
            if (!Array.isArray(json)) {
                throw({ message: "Bạn phải nhập vào 1 mảng" })
            }
            return json
        } catch(err){
            throw({ message: "Định dạng không hợp lệ" })
        }
    }

    const changeValueJson = (values) => {
        try {
            // console.log('changeValueJson', values);
            if (values) {
                let json = checkValueJson(values);

                setDataForm({
                    ...dataForm,
                    fields: json
                })
            } else {
                setDataForm({
                    ...dataForm,
                    fields: []
                })
            }
        } catch (err) {
            message.warning(err.message);
        }
    }

    const previewData = () => {
        setdataString(viewEditor.current.state.doc.toString());
    }
    const setValueEditor = (values) => {
        console.log("set value")
        let transaction = viewEditor.current.state.update({changes: {from: 0, to: viewEditor.current.state.doc.length, insert: values }})
        viewEditor.current.dispatch(transaction)
    }

    useEffect(() => {
        changeValueJson(dataString);
    }, [dataString])

    useEffect(() => {
        if (visible) {
            if (formRef.current) {
                if (props.item) {
                    formRef.current.setFieldsValue(props.item);
                    setValueEditor(JSON.stringify(props.item.fields, null, 2))
                } else {
                    formRef.current.resetFields();
                    setValueEditor("[\n\t{..}\n]");
                }
            }

        } else {
            setdataString("");
        }

    }, [visible])

    return (
        <Modal
            title="Form hành động"
            visible={visible}
            onCancel={hide}
            footer={null}
            width={1400}
        >
            <div className="row">
                <div className="col-md-5">
                    {/* <Form.Item label="Kiểu nhập">
                        <Radio.Group
                            defaultValue={inputType}
                            onChange={(e) => setinputType(e.target.value)}
                        >
                            <Radio value={"input"} disabled>Input</Radio>
                            <Radio value={"json"}>Json</Radio>
                        </Radio.Group>
                    </Form.Item> */}

                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        ref={formRef}
                    >
                        <div className="row">
                            <Form.Item
                                name="platform"
                                label="Platform"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống"
                                    }
                                ]}
                                className="col-md-6"
                            >
                                <Select placeholder="Chọn platform">
                                    {
                                        platform.map((item, i) => {
                                            return <Select.Option key={i} value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="key"
                                label="Key"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống"
                                    }
                                ]}
                                className="col-md-6"
                            >
                                <Input placeholder="Vd: like_post_facebook" />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Loại"
                                className="col-md-6"
                            >
                                <Select placeholder="Chọn loại">
                                    {
                                        Object.keys(Config.typeFormAction).map((item, i) => {
                                            return <Select.Option key={i} value={item}>{Config.typeFormAction[item].label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item name="value" label="Tên"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống"
                                }
                            ]}
                        >
                            <Input placeholder="Nhập tên hiển thị hành động.." />
                        </Form.Item>
                        {inputType == "json" && (
                            <Form.Item label="Data array">
                                {/* <Input.TextArea rows={6} placeholder="Nhập dữ liệu vào đây.." onChange={(e) => {
                                    if(changeTimeout.current) clearTimeout(changeTimeout.current);
                                    changeTimeout.current = setTimeout(() => {
                                        changeValueJson(e.target.value)
                                    }, 200)
                                }}/> */}
                                <WidgetInputCodeMirror
                                    visible={visible}
                                    viewEditorRef={viewEditor}
                                />
                            </Form.Item>
                        )}
                        <Form.Item className="d-flex">
                            <Button htmlType="submit" type="primary" loading={loading}>
                                Cập nhập
                            </Button>
                            <Button type="primary" className="ml-2" onClick={previewData}>
                                Preview
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="col-md-7 border-l">
                    <Form
                        layout='vertical'
                        ref={formPreview}
                    >
                        <WidgetFormRender
                            action={dataForm}
                            setDefaultValueForm={(values) => formPreview.current.setFieldsValue(values)}
                        />
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default WidgetFormData;
