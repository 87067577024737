import React, { Component } from 'react';

import { Modal, Button, Input, Form} from 'antd';

export default class formFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.form = null;
    }
    onSave = async (values) => {
        try {
            this.setState({ loading: true });
            let url = `api/v1/folder/create`;
            let method = 'post';

            if(this.props.visible.type == "update"){
                url = `api/v1/folder/${this.props.visible.folder_id}`;
                method = 'put';
            } else {
                values['parent_id'] = this.props.visible.folder_id;
            }
            let resutl = await this.props.fetchData({
                url: url,
                method: method,
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false }, () => {
                this.props.notification({
                    message: "Quản lý nhóm",
                    description: `${this.title} "${this.props.visible.folder_name || resutl.data.folder_name}" thành công`,
                    type: 'success'
                })
                // this.props.getListData();
                this.props.getListFolder();
                this.props.hide();
            });
        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    message: "Quản lý nhóm",
                    description: err.message,
                    type: 'error'
                })
            });
        }
    };
    componentWillUpdate = async (nextProps, nextState) => {
        if(nextProps.visible !== this.props.visible && !nextProps.visible && this.form){
            this.form.resetFields();
        }
    }
    render() {
        if(!this.props.visible) return null
        this.title = "Thêm folder";
        if(this.props.visible.type == 'update'){
            this.title = "Sửa folder";
        }
        return (
            <Modal
                title={this.title}
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <Form
                    onFinish={this.onSave}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        folder_name: this.props.visible.type == 'update' ? this.props.visible.folder_name : ""
                    }}
                    layout="vertical"
                >
                    <Form.Item
                        name="folder_name"
                        rules={[{ required: true, message: 'Folder name không được để trống!' }]}
                    >
                        <Input placeholder="folder name"/>
                    </Form.Item>
                    <div className="text-right">
                        <Button type="primary" loading={this.state.loading} htmlType="submit">
                            Lưu
                        </Button>
                    </div>
                </Form>
            </Modal>
        )
    }
}
