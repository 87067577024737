import React from "react";
import { AccountMetaData } from "../../lib/account";
import { API_URL } from "../../lib/api";

const JobQueue = (props) => {
    return <>
        <div className="block">
            <div className="block-content">
                <iframe 
                    src={API_URL.host+"/"+API_URL.job}
                    allowFullScreen={true}
                    height={1000}
                    width={"100%"}
                    frameBorder={false}
                />
            </div>
        </div>
    </>
}

export default JobQueue;