import React, { useEffect, useRef } from "react";
import { EditorState } from "@codemirror/state";
import { jsonLanguage } from "@codemirror/lang-json";
import { basicSetup, EditorView } from "codemirror"

const WidgetInputCodeMirror = (props) => {
    const { visible, viewEditorRef } = props;

    const editor = useRef();

    useEffect(() => {
        try{
            if(!viewEditorRef.current){
                const state = EditorState.create({
                    doc: `[\n\t{..}\n]`,
                    extensions: [basicSetup, jsonLanguage]
                });

                viewEditorRef.current = new EditorView({ state, parent: editor.current });

            }
        } catch(err){
            console.log(err);
        }

        return () => {
            if(viewEditorRef.current){
                viewEditorRef.current.destroy();
                viewEditorRef.current = null;
            }
        }
    }, [visible])

    const setValue = (values) => {
        console.log("set value")
        let transaction = viewEditorRef.current.state.update({changes: {from: 0, to: viewEditorRef.current.state.doc.length, insert: values }})
        viewEditorRef.current.dispatch(transaction)
    }

    const getValue = () => {
        return viewEditorRef.current.state.doc.toString()
    }

    return <React.Fragment>
        <div ref={editor}></div>
    </React.Fragment>
}

export default WidgetInputCodeMirror;