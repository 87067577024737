import React from "react";

import { ListActionView } from "../../../index"

const AdminActionView = (props) => {
    return <ListActionView 
        {...props}
        roleTypeAdmin={true}
    />
}

export default AdminActionView;