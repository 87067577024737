import React, { Component, useEffect, useMemo, useRef, useState } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Select, notification, InputNumber } from 'antd';
import { fetchData } from '../../lib/helpers';
import { API_URL } from '../../lib/api';

const ListFields = [
    {
        name: "full_name",
        label: "Full name"
    },
    {
        name: "username",
        label: "User name"
    },
    {
        label: "UID",
        name: "uid"
    },
    {
        label: "Password",
        name: "password"
    },
    {
        label: "Email",
        name: "email"
    },
    {
        label: "Password Email",
        name: "password_email"
    },
    {
        label: "Cookie",
        name: "cookie"
    },
    {
        label: "Token",
        name: "access_token"
    },
    {
        label: "Birthday",
        name: "birthday"
    },
    {
        label: "User agent",
        name: "user_agent"
    },
    {
        label: "Secret 2fa",
        name: "secret_2fa"
    },
    {
        label: "Proxy",
        name: "proxy"
    },
    {
        label: "Device",
        name: "device_id"
    },
    {
        label: "Link backup",
        name: "link_backup"
    },
    {
        label: "Url login",
        name: "url_login"
    },
    {
        label: "Email recover",
        name: "email_recover"
    },,
    {
        label: "Location",
        name: "location"
    },
    {
        label: "Gender",
        name: "gender"
    },
    {
        name: "relationship_status",
        label: "Relationship status"
    },
    {
        name: "friends_count",
        label: "Friends",
        type: "inputNumber"
    },
    {
        name: "groups_count",
        label: "Groups",
        type: "inputNumber"
    },
]

const InfoUserFb = (props) => {
    const { user, visible, hide } = props;

    const [ dataUpdate, setdataUpdate ] = useState({});
    const [ loading, setloading ] = useState(false);

    const refForm = useRef();

    const logs = useMemo(() => {
        let logs = user.logs || [];
        logs.sort((a, b) => b.time - a.time);
        logs = logs.map((log) => `${new Date(log.time)} ---> ${log.log} \n`);

        return logs;
    }, [user])

    useEffect(() => {
        if(visible && refForm.current){
            refForm.current.setFieldsValue(user);
            setdataUpdate({});
        }
    }, [ visible, user ])

    const onFinish = async () => {
        try{
            let values = dataUpdate;

            setloading(true);
            let res = await fetchData({
                url: API_URL.account.update+"/"+user._id,
                method: "put",
                body: JSON.stringify(values)
            })
            notification.success({
                message: "Thông báo",
                description: "Thao tác thành công"
            })
            hide();
        } catch(err){
            notification.warning({
                message: "Thông báo",
                description: err.message || "Đã có lỗi xảy ra!"
            })
        } finally{
            setloading(false);
        }
    }

    return (
        <Modal
            title="Thông tin tài khoản"
            visible={visible}
            onCancel={hide}
            footer={null}
            width={700}
        >
            <Form
                layout="vertical"
                ref={refForm}
                onValuesChange={(e) => setdataUpdate((prev) => ({...prev, ...e}))}
            >
                <div className='row'>
                    {
                        ListFields.map((field, key) => {
                            return <Form.Item className='col-6' key={key} name={field.name} label={field.label}>
                                {
                                    field.name == "gender" ?
                                    <Select>
                                        <Select.Option value={0}>Không xác định</Select.Option>
                                        <Select.Option value={1}>Nam</Select.Option>
                                        <Select.Option value={2}>Nữ</Select.Option>
                                    </Select>
                                    :
                                    field.type == "inputNumber" ? 
                                    <InputNumber className='w-100'/>
                                    :
                                    <Input />
                                }
                            </Form.Item>
                        })
                    }
                </div>
                <Form.Item className='text-right'>
                    <Button
                        onClick={() => {
                            refForm.current.setFieldsValue(user);
                        }}
                        className='mr-3'
                    >Reset</Button>
                    <Button type='primary'
                        disabled={!Object.keys(dataUpdate).length}
                        onClick={onFinish}
                        loading={loading}
                    >Lưu thay đổi</Button>
                </Form.Item>
            </Form>
            
            <div className="form-group">
                <label>Log</label>
                <textarea value={logs.join('\n')} className="form-control" style={{ minHeight: "150px" }} />
            </div>
        </Modal>
    )
}

export default InfoUserFb;
