const chrome = window.chrome

class ExtError extends Error {
  constructor(status, error = '') {
    super(error)
    this.status = status
    this.error = error
    this.message = `ExtError ${status}: ${error.response ? error.response.message : error}`
    this.response = error.response
  }
}

class TimeoutError extends Error {}
class NotInstalledError extends Error {}

const generate_req_id = (() => {
  let req_id = Date.now()
  return () => req_id++
})()

class ExtfbaseConnector {
  _currend_id = ''
  _proflie_id = ''
  ext_id = process.env.REACT_APP_KEY_EXT

  isStopped = false
  isConnected = false

  port = null
  info = {}

  versionCompare = (v1, v2, options) => {
    var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split('.'),
        v2parts = v2.split('.');
 
    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }
 
    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }
 
    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }
 
    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }
 
    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }
 
        if (v1parts[i] == v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }
 
    if (v1parts.length != v2parts.length) {
        return -1;
    }
 
    return 0;
}

  _check_working_id = (ver_ext) =>
    new Promise((resolve, reject) => {
      setTimeout(
        () => reject('Cant find any working extension'),
        500,
      )
      chrome.runtime.sendMessage(
        this.ext_id,
        { method: 'ping' },
        null,
        ({ status, pong, profile_id, manifest} = {}) => {
          if(status === 'ok' && pong && manifest && this.versionCompare(ver_ext, manifest.version) != 1){
            return resolve({ ext_id: this.ext_id, profile_id, manifest})
          } else {
            reject('Can not connect');
          }
        }
      )

    })

  _try_connect_port = () =>
    new Promise((resolve, reject) => {
      const port = chrome.runtime.connect(this._currend_id)

      port.onDisconnect.addListener(() => resolve(null))

      setTimeout(() => {
        resolve(port)
      }, 100)
    })

  init = async ({ver_ext}) => {
    if(!chrome.runtime){
      this.isConnected = false
      return
    }
    try {
      let check_connect = await this._check_working_id(ver_ext);
      this._currend_id = check_connect.ext_id;
      this._profile_id = check_connect.profile_id;
      this.manifest = check_connect.manifest;

      this.port = await this._try_connect_port()

      this.isConnected = true

    } catch (err) {
      if (err instanceof TimeoutError) {
        this.isConnected = false
        return
      }

      throw err
    }
  }

  start = () => (this.isStopped = false)
  kill = () => (this.isStopped = true)

  request = (data, wake = false) =>
    new Promise((resolve, reject) => {
      const wasWorking = !this.isStopped

      if (wake && !wasWorking) this.start()

      if (this.isStopped) return reject({
        status_code: 501,
        message: 'Request was skipped'
      })
      if (!this.isConnected) return reject({
        status_code: 0,
        message: 'Not connection'
      })

      // setTimeout(() => reject('Request timeout'), 10000)

      const req_id = generate_req_id()

      const onResponse = message => {
        if (!message) return reject({
          status_code: 502,
          message: 'Not Message'
        })

        const { status, error } = message

        if (req_id !== message.req_id) { return }

        // console.log('request', message)

        if (status !== 'ok') {
          reject(error)
        } else {
          resolve(message)
        }

        this.port && this.port.onMessage.removeListener(onResponse)
      }

      // console.log(`send_message`, this._currend_id, data)

      if (!this.port) {
        chrome.runtime.sendMessage(this._currend_id, data, null, onResponse)
      } else {
        this.port.onMessage.addListener(onResponse)
        try{
          this.port.postMessage({ req_id, ...data })
        } catch(err){
          reject({
            status_code: 1,
            error: err,
            message: 'Không thể connect tới tiện ích. Vui lòng kiểm tra lại!'
          })
        }
      }

      if (wake && !wasWorking) this.kill()
    })
}

const extfbase = new ExtfbaseConnector()

window.extfbase = extfbase

export default extfbase
export { ExtfbaseConnector, ExtError, NotInstalledError, TimeoutError }
