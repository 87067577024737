import { combineReducers } from "redux";

import Access from "./containers/access/reducer";
import TaskAction from "./containers/taskAction/reducer";

const rootReducer = combineReducers({
    Access: Access,
    TaskAction: TaskAction
})

export default rootReducer;