import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Select, TreeSelect} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class FormAddUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalGet: 0,
            listFolderUs: [],
            loadingData: false,
            success: 0,
            error: 0,
            logs: []
        }
        this.form = null;
    }
    componentWillMount = () => {

    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.getData();
        }
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true })
            if (!window.extfbase || !window.extfbase.isConnected) {
                throw ({ message: 'Vui lòng kiểm tra tiện ích Fbase phiên bản mới nhất của chúng tôi!' });
            }
            let thread = 10;
            while (this.state.loading) {
                let promise = [];
                for (let i = 0; i < thread; i++) {
                    promise.push(new Promise(async (resolve, reject) => {
                        try {
                            let response = await this.props.fetchData({
                                url: `api/v1/user_fb/new?folder_id=${this.props.currentFolder ? this.props.currentFolder : ''}&type=add_data_us`,
                                method: 'get'
                            });
                            console.log(response.data);
                            if (response.data) {
                                thread = 10;
                                try {
                                    let result = await window.extfbase.request({
                                        method: 'mhcp_us',
                                        data: {
                                            uss_token: this.props.userSystem.token_us,
                                            hashgroup: values['hashgroup'],
                                            account: `${response.data.uid}|${response.data.password}|${response.data.cookie}`,
                                            server_photo: 15
                                        },
                                        option: {
                                            url: `https://m8.mymin.net/api/tools/account/mhcp_account.php?action=import&parseFriends=true`,
                                            method: 'post'
                                        }
                                    });
                                    console.log(result);
                                    let postmail = await window.extfbase.request({
                                        method: 'mhcp_us',
                                        data: {
                                            uss_token: this.props.userSystem.token_us,
                                            update: true,
                                            format: 1,
                                            emails: `${response.data.uid}|${response.data.email}|${response.data.password_email}`
                                        },
                                        option: {
                                            url: `https://m10.mcare.me/api/tools/mhcp_changeinfo.php?action=email.add`,
                                            method: 'post'
                                        }
                                    });
                                    await this.props.fetchData({
                                        url: `api/v1/user_fb/${response.data._id}`,
                                        method: "put",
                                        body: JSON.stringify({
                                            status_sync_us: 2
                                        }),
                                        headers: { 'Content-Type': 'application/json' }
                                    })
                                    console.log('success ', result, postmail, response.data._id);
                                    this.state.logs.push(new Date() + ' --> ' + "Đẩy dữ liệu thành công: "+ response.data.uid)
                                    this.setState({
                                        success: this.state.success + 1,
                                        logs: this.state.logs
                                    })
                                } catch (err) {
                                    console.log(err);
                                    let message = err.message || "Đã có lỗi xảy ra";
                                    await this.props.fetchData({
                                        url: `api/v1/user_fb/${response.data._id}`,
                                        method: "put",
                                        body: JSON.stringify({
                                            status_sync_us: 3,
                                            status: message.indexOf('Cookie die') > -1 ? 2 : undefined
                                        }),
                                        headers: { 'Content-Type': 'application/json' }
                                    })
                                    this.state.logs.push(new Date() + ' --> ' + message)
                                    this.setState({
                                        error: this.state.error + 1,
                                        logs: this.state.logs
                                    })
                                }
                            } else {
                                thread = 1;
                            }
                        } catch (err) {
                            console.log(err);
                        }
                        resolve(true);
                    }))
                }
                await Promise.all(promise);
                await this.props.sleep(1000);
            }

            this.setState({ loading: false })
        } catch (err) {
            this.setState({ loading: false })
            this.props.notification({
                type: 'warning',
                message: 'Đẩy dữ liệu sang Us',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    };
    getData = async () => {
        try {
            this.setState({ loadingData: true });

            let folder_us = await window.extfbase.request({
                method: 'mhcp_account', data: {
                    uss_token: this.props.userSystem.token_us,
                    action: 'get.hashgroups'
                }
            });

            this.setState({
                loadingData: false,
                listFolderUs: this.parseFolderUs(folder_us.data.data)
            })
        } catch (err) {
            this.setState({ loadingData: false });
            this.props.notification({
                type: 'warning',
                message: 'Đẩy dữ liệu sang Us',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    stop = () => {
        this.setState({
            loading: false
        })
    }
    parseFolderUs = (list) => {
        var roots = [];

        for (let i = 0; i < list.length; i++) {
            let result = {
                title: list[i]['name'],
                value: list[i]['id'],
                children: []
            }
            if (list[i]['childs']['node'].length > 0) {
                let childs = list[i]['childs']['node'];
                childs.forEach((item) => {
                    result['children'].push({
                        title: item.name,
                        value: item.id
                    })
                })
            }
            roots.push(result)
        }
        return roots;
    }
    render() {
        return (
            <Modal
                title="Đẩy dữ liệu sang Us"
                visible={this.props.visible}
                onCancel={() => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.hide();
                    })
                }}
                footer={null}
                className="modal-getcookie"
                width={600}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                    }}
                    layout="vertical"
                >
                    <div style={{ marginBottom: "15px" }}>
                        <div className="block-content mb-5 bt-1" style={{ border: "1px solid #f6f7f9", borderTop: "0px" }}>
                            <p className="text-success mb-5">Thành công: {this.state.success}</p>
                            <p className="text-danger mb-5">Lỗi: {this.state.error}</p>
                        </div>
                    </div>
                    <Form.Item>
                        <Input.TextArea value={this.state.logs.join('\n \n')} style={{minHeight: "160px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="hashgroup"
                        label="Folder"
                    >
                        <TreeSelect
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={this.state.listFolderUs}
                            placeholder="Tìm tên hoặc id nhóm"
                            treeDefaultExpandAll={false}
                            allowClear
                            showSearch
                            disabled={this.state.loadingData}
                            filterOption={(input, option) => {
                                if (option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value == input) {
                                    return option
                                }
                            }}
                        />
                    </Form.Item>

                    <div className="text-right">
                        {
                            this.state.loading ? 
                            <Button type="danger" htmlType="button" onClick={() => this.setState({ loading: false })} style={{marginRight: "10px"}}>
                                Dừng
                            </Button> : null
                        }
                        <Button type="primary" htmlType="submit" disabled={this.state.loadingData} loading={this.state.loading}>
                            Tiếp tục
                        </Button>
                    </div>
                </Form>
            </Modal>
        )
    }
}
