import { ROOT_ACTION } from "../contants";

export const viewDetailTask = (payload) => {
    return {
        type: ROOT_ACTION.taskAction.viewDetail,
        payload
    }
}

export default {
    viewDetailTask
}