import { ROOT_ACTION } from "../contants";

export const getActionPlatform = (payload) => {
    return {
        type: ROOT_ACTION.platform.action.get,
        payload
    }
}

export const updateStatePlatform = (payload) => {
    return {
        type: ROOT_ACTION.platform.updateSate,
        payload
    }
}

export default {
    getActionPlatform,
    updateStatePlatform
}