import React, { Component, useEffect, useRef, useState } from 'react';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Switch, Table, Spin, Tag, Space, DatePicker, InputNumber } from 'antd';
import { fetchData, formatNumber } from '../../lib/helpers';
import { Footer, Header, Sidebar } from '../../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import qs from 'qs';
import { connect } from 'react-redux';
import { PackageNameUser } from '../../utils/config';
import dayjs from 'dayjs';

const UserView = (props) => {
    const { Access } = props;

    const [data, setData] = useState({
        listData: [],
        total: 0,
    })

    const [loading, setloading] = useState(false);
    const [loadingForm, setloadingForm] = useState(false);
    const [visibleForm, setvisibleForm] = useState(false);
    const [visibleFormLicense, setvisibleFormLicense] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20
    });

    const formFilter = useRef();
    const form = useRef();
    const formLicense = useRef();
    const timeoutfilter = useRef();

    const levels = {
        1: "Người dùng",
        2: "Acc thuê nick",
        5: "Get acc",
        6: "CTV",
        90: "Acc trash",
        99: "Admin",
        // 999: "Root"
    };

    const status = {
        0: {
            label: "Chưa kích hoạt"
        },
        1: {
            label: "Hoạt động",
            color: "green"
        },
        2: {
            label: "Tạm khóa",
            color: "red"
        }
    }

    useEffect(() => {
        getListData();
    }, [pagination])

    const getListData = async () => {
        try {
            setloading(true);

            let params = {
                ...pagination
            }
            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };
            let response = await fetchData({
                url: `api/v1/admin/user/list?${qs.stringify(params)}`,
                method: 'get'
            });

            setData({ listData: response.data, total: response.total })

        } catch (err) {
            props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        } finally {
            setloading(false);
        }
    }

    const save = async (values) => {
        try {
            setloadingForm(true);

            values['role'] = {};

            if (values['platforms']) {
                values['role']['platforms'] = values.platforms.map((item) => {
                    let date = item.expired_date;
                    delete item.expired_date;
                    return {
                        ...item,
                        expired_time: dayjs(date).unix() * 1000
                    }
                })
            }
            if(typeof values['max_app'] == "number") {
                values['role']['max_app'] = values['max_app']
            }
            if (values['packages']) {
                values['role']['packages'] = values.packages;
            }

            let response = await fetchData({
                url: `api/v1/admin/user/${values['_id']}`,
                method: 'put',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            props.message({ content: "Thao tác thành công", type: 'success' });

            getListData();

            setvisibleForm(false);
        } catch (err) {
            props.message({ content: err.message || 'Đã cõ lỗi xảy ra', type: 'error' });
        } finally {
            setloadingForm(false);
        }
    }
    return (
        <React.Fragment>
            <div className="row ">
                <Modal
                    title="Cập nhập người dùng"
                    visible={visibleForm}
                    onCancel={() => {
                        setvisibleForm(false);
                        form.current.resetFields()
                    }}
                    footer={null}
                >
                    <Form
                        ref={form}
                        onFinish={save}
                        initialValues={{

                        }}
                        layout="vertical"
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="level" label="Level">
                            <Select placeholder="Level">
                                {
                                    Object.keys(levels).map((key) => {
                                        return <Select.Option value={parseInt(key)}>{levels[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="status" label="Status">
                            <Select placeholder="Status">
                                {
                                    Object.keys(status).map((key) => {
                                        return <Select.Option value={parseInt(key)}>{status[key].label}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="ref" label="Ref">
                            <Input placeholder='ref' />
                        </Form.Item>
                        <Form.Item name="note" label="Note">
                            <Input.TextArea placeholder='Ghi chú' />
                        </Form.Item>
                        {/* <Form.Item name="platforms">
                            <Select placeholder="Nền tảng" mode="multiple">
                                {
                                    Access.Platform.data.map((item) => {
                                        return <Select.Option value={item}>{item}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item> */}
                        {/* <Form.Item name="packages">
                            <Select placeholder="Tính năng khác" mode="multiple">
                                {
                                    Object.keys(PackageNameUser).map((key) => {
                                        return <Select.Option value={key}>{PackageNameUser[key].label}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item> */}
                        <div className="d-flex justify-content-end" >
                            <Button type="primary" htmlType="submit" loading={loadingForm}>Cập nhập</Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title="Cập nhập license"
                    visible={visibleFormLicense}
                    onCancel={() => {
                        setvisibleFormLicense(false);
                        formLicense.current.resetFields()
                    }}
                    footer={null}
                >
                    <Form
                        ref={formLicense}
                        onFinish={save}
                        initialValues={{

                        }}
                        layout="vertical"
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.List name="platforms">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} className='w-100 row'>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'type']}
                                                rules={[{ required: true, message: 'Không được để trống' }]}
                                                className='col-5 mb-3'
                                            >
                                                <Select placeholder="Chọn nền tảng">
                                                    {
                                                        Access.Platform.data.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'expired_date']}
                                                rules={[{ required: true, message: 'Missing last name' }]}
                                                className='col-5  mb-3'
                                            >
                                                <DatePicker 
                                                    presets={[
                                                        { label: '7 ngày', value: dayjs().add(7, 'day') },
                                                        { label: '1 tháng', value: dayjs().add(1, 'month') },
                                                        { label: '2 tháng', value: dayjs().add(2, 'month') },
                                                        { label: '3 tháng', value: dayjs().add(3, 'month') },
                                                        { label: '6 tháng', value: dayjs().add(6, 'month') },
                                                        { label: '1 năm', value: dayjs().add(1, 'year') },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <CloseOutlined onClick={() => remove(name)} />
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item name="max_app" label="Số thiết bị">
                            <InputNumber min={0} className="w-100"/>
                        </Form.Item>

                        <div className="d-flex justify-content-end" >
                            <Button type="primary" htmlType="submit" loading={loadingForm}>Cập nhập</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="col-md-12 ">
                    <div className="block">
                        <div className="block-content block-content-m">
                            <div className="row mb-20">
                                <div className="col-md-9">
                                    <button className="btn btn-info btn-sm">Tất cả ({data.total})</button>
                                    <button className="btn btn-primary btn-sm ml-2" onClick={() => getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <Form
                                ref={formFilter}
                                initialValues={{

                                }}
                                className="row"
                            >
                                <Form.Item name="keyword" className="col-md-2">
                                    <Input placeholder="Tìm kiếm.." onChange={() => {
                                        if (timeoutfilter.current) clearTimeout(timeoutfilter.current);
                                        timeoutfilter.current = setTimeout(() => {
                                            getListData('reset');
                                        }, 300)
                                    }} />
                                </Form.Item>
                                <Form.Item name="ref" className="col-md-2">
                                    <Input placeholder="Tìm kiếm theo ctv" onChange={() => {
                                        if (timeoutfilter.current) clearTimeout(timeoutfilter.current);
                                        timeoutfilter.current = setTimeout(() => {
                                            getListData('reset');
                                        }, 300)
                                    }} />
                                </Form.Item>
                                <Form.Item name="level" className="col-md-2">
                                    <Select onChange={() => getListData('reset')} placeholder="level">
                                        {
                                            Object.keys(levels).map((key) => {
                                                return <Select.Option value={parseInt(key)}>{levels[key]}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="status" className="col-md-2">
                                    <Select placeholder="Status" onChange={() => getListData('reset')}>
                                        {
                                            Object.keys(status).map((key) => {
                                                return <Select.Option value={parseInt(key)}>{status[key].label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="platforms" className="col-md-2">
                                    <Select placeholder="Chọn nền tảng"
                                        onChange={() => getListData('reset')}
                                    >
                                        {
                                            Access.Platform.data.map((item) => {
                                                return <Select.Option value={item}>{item}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item className="col-md-1">
                                    <Button htmlType="reset" type="primary" onClick={(e) => {
                                        formFilter.current.resetFields();
                                        getListData('reset');
                                    }}>Xóa lọc</Button>
                                </Form.Item>
                            </Form>
                            <div className="row ">
                                <div className="col-md-12 ">
                                    <Spin spinning={loading}>
                                        <Table
                                            columns={[
                                                {
                                                    title: "#",
                                                    key: "index",
                                                    render: (value, item, index) => {
                                                        if (pagination.page == 1) {
                                                            return index + 1;
                                                        } else {
                                                            return (pagination.page - 1) * pagination.limit + 1
                                                        }
                                                    }
                                                },
                                                {
                                                    title: "Full name",
                                                    key: "full_name",
                                                    dataIndex: "full_name",
                                                    render: (item, record) => {
                                                        return <>
                                                            <p className='mb-1'>{item}</p>
                                                            <p className='mb-1'>{record.user_name}</p>
                                                            <p className='mn-0'><Tag>{levels[record.level]}</Tag></p>
                                                        </>
                                                    }
                                                },
                                                {
                                                    title: "Status",
                                                    key: "status",
                                                    dataIndex: "status",
                                                    render: (item) => status[item] && <Tag color={status[item].color}>{status[item].label}</Tag>
                                                },
                                                {
                                                    title: "Platform",
                                                    dataIndex: "role",
                                                    render: (item) => {
                                                        return item && item.platforms?.map((item) => {
                                                            return <>
                                                                <p className='mb-0'>{typeof item == "object" ?
                                                                    <>
                                                                        <Tooltip
                                                                            title={`License: ${moment(item.expired_time).format("hh:mm DD/MM/YYYY")}`}
                                                                        >
                                                                            <span className={item.expired_time - moment().unix() * 1000 > 0 ? "text-success" : "text-danger"}>{item.type}</span>
                                                                        </Tooltip>
                                                                    </>
                                                                    : item}</p>
                                                            </>
                                                        })
                                                    }
                                                },
                                                // {
                                                //     title: "Packages",
                                                //     dataIndex: "role",
                                                //     render: (item) => {
                                                //         return item && item.packages?.map((item) => PackageNameUser[item]?.label).join(", ")
                                                //     }
                                                // },
                                                {
                                                    title: "Last time",
                                                    key: "last_time",
                                                    dataIndex: "last_time",
                                                    render: (item) => item ? moment(item).format('HH:mm DD/MM/YYYY') : ""
                                                },
                                                {
                                                    title: "Created time",
                                                    key: "created_time",
                                                    align: "right",
                                                    dataIndex: "created_time",
                                                    render: (created_time) => created_time ? moment(created_time).format('HH:mm DD/MM/YYYY') : ""
                                                },
                                                {
                                                    title: "Action",
                                                    key: "action",
                                                    className: "text-right",
                                                    render: (item) => {
                                                        return <div>
                                                            <Tooltip title="Chỉnh sửa">
                                                                <a href="#" className="btn btn-primary ml-5 p-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setvisibleForm(true);
                                                                    setTimeout(() => {
                                                                        if (form) {
                                                                            form.current.setFieldsValue({
                                                                                ...item
                                                                            })
                                                                        }
                                                                    }, 50);
                                                                }}>
                                                                    <i class="far fa-edit"></i>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="Kích hoạt license">
                                                                <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setvisibleFormLicense(true);
                                                                    setTimeout(() => {
                                                                        if (form) {
                                                                            formLicense.current.setFieldsValue({
                                                                                ...item,
                                                                                platforms: item.role?.platforms?.filter((item) => typeof item == "object").map((item) => {
                                                                                    return {
                                                                                        ...item,
                                                                                        expired_date: dayjs(item.expired_time)
                                                                                    }
                                                                                }),
                                                                                max_app: item.role?.max_app
                                                                            })
                                                                        }
                                                                    }, 50);
                                                                }}>
                                                                    <i class="far fa-user"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                }
                                            ]}
                                            dataSource={data.listData}
                                            pagination={{
                                                total: data.total,
                                                page: pagination.page,
                                                pageSize: pagination.limit,
                                                onChange: (current, pageSize) => {
                                                    setpagination({
                                                        page: current,
                                                        limit: pageSize
                                                    })
                                                }
                                            }}
                                            scroll={{
                                                x: 900
                                            }}
                                        />
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispathToProps)(UserView);