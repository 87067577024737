import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { ROOT_ACTION } from "../../contants";

export function* getActionPlatform(payload){
    try{
        const posts = yield call(fetchData, {
            url: API_URL.platform.action.index,
            params: payload.payload
        });
        yield put({ type: ROOT_ACTION.platform.action.getSuccess, data: posts });
    } catch(err){
        yield put({ type: ROOT_ACTION.platform.action.getError, data: err });
    }
}

export default function* myAccess(){
    yield takeLatest(ROOT_ACTION.platform.action.get, getActionPlatform)
}