import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber } from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class FormCheckUid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data_error: [],
            data_success: []
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });
            let uids = [...this.props.listUidChecked];
            localStorage.setItem('token_checkuid', values['token']);
            while(uids.length > 0){
                if(!this.props.visible) break;
                let uids_action = uids.splice(0, 100);
                let promise = [];
                for(let i = 0; i < uids_action.length; i++){
                    promise.push(this.requestGraph(values['token'], uids_action[i]))
                }
                let response = await Promise.all(promise);
                let data_error = response.filter((item) => item.status == 2);
                let data_success = response.filter((item) => item.status == 1);
                data_error = data_error.map((item) => item.id);
                data_success = data_success.map((item) => item.id);
                
                await this.props.fetchData({
                    url: 'api/v1/user_fb/update_status',
                    method: 'post',
                    body: JSON.stringify({
                        data_error: data_error,
                        data_success: data_success
                    }),
                    headers: { 'Content-Type': 'application/json' }
                });

                await this.sleep(2000);
            }
            this.setState({ loading: false });
            this.props.notification({ 
                description: 'Xử lý thành công!',
                type: 'success',
                key: 'success',
                message: 'Check uid'
            })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error',
                    message: 'Check uid'
                })
            });
        }
    };
    cancel = () => {
        // this.form.resetFields();
        this.setState({
            data_error: [],
            data_success: []
        })
        this.props.resetData();
        this.props.hide();
    }
    requestGraph = async (token, id) => {
        return new Promise((resolve, reject) => {
            fetch(`https://graph.facebook.com/${id}/picture?type=normal`)
            .then(response =>  {
                return response.url.indexOf("https://static.xx.fbcdn.net/rsrc.php") > -1 ? 403 : response.status;
            })
            .then((response) => {
                let data_error = this.state.data_error;
                let data_success = this.state.data_success;

                if(response != 200){
                    this.setState({
                        data_error: data_error.concat(id)
                    }, () => {
                        return resolve({
                            status: 2,
                            id: id
                        })
                    })
                } else{
                    this.setState({
                        data_success: data_success.concat(id)
                    }, () => {
                        return resolve({
                            status: 1,
                            id: id
                        })
                    })
                }
            }).catch((err) => {
                console.log(err);
                return reject(err);
            })
        })
    }
    sleep = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(ms);
            }, ms)
        });
    }
    render() {
        return (
            <Modal
            title="Kiểm tra nick sống/chết"
            visible={this.props.visible}
            onCancel={this.cancel}
            footer={null}
            >
                <Form
                        onFinish={this.onFinish}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            token: localStorage.getItem('token_checkuid') ? localStorage.getItem('token_checkuid') : ''
                        }}
                    >
                        <div className="d-flex" style={{justifyContent: "space-between"}}>
                            <p>
                                Tổng số: {this.props.listUidChecked.length}
                            </p>
                            <p>
                                Nick sống: <span className="text-success">{this.state.data_success.length}</span>
                            </p>
                            <p>
                                Nick chết: <span className="text-danger">{this.state.data_error.length}</span>
                            </p>
                        </div>
                        {/* <Form.Item 
                            name="token"
                            label="Token"
                            rules={[{ required: true, message: 'Bạn phải nhập token!' }]}
                        >
                            <Input placeholder="Nhập token" />
                        </Form.Item> */}

                        <div className="text-center">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                Kiểm tra
                            </Button>
                        </div>
                    </Form>
            </Modal>
        )
    }
}
