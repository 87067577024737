import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, TreeSelect, Alert } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class SyncFbaseTous extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data_success: [],
            data_error: [],
            data_pending: []
        }
        this.form = null;
    }
    componentDidMount = () => {

    }
    cancel = () => {
        this.props.resetData();
        this.setState({
            loading: false,
            data_pending: [],
            data_success: [],
            data_error: []
        })
        this.props.hide();
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            if (!this.props.userSystem.token_us) {
                this.props.notification({
                    description: 'Vui lòng cập nhập token us',
                    type: 'error',
                    message: 'Đồng bộ dữ liệu us'
                })
            } else {
                this.setState({
                    data_pending: nextProps.listIdChecked ? JSON.parse(JSON.stringify(nextProps.listIdChecked)) : []
                })
            }
        }
    }
    syncData = async () => {
        try {
            this.setState({
                loading: true
            })
            while(true){
                let items = this.state.data_pending.splice(0, 20);
                if(items.length == 0 || !this.state.loading) break;
                let promise = [];
                for(let key in items){
                    let find = this.props.listData.find((item) => item._id === items[key]);
                    if(find) promise.push(this.saveSyncData(find));
                }
                await Promise.all(promise);
            }
            this.setState({
                loading: false
            })
            
        } catch (err) {
            console.log(err);
            this.props.notification({
                description: err.message || 'Đã có lỗi xảy ra',
                type: 'error',
                message: 'Lấy danh sách group us'
            })
        }
    }
    saveSyncData = async (item) => {
        return new Promise(async (resolve, reject) => {
            try{
                let data = {
                    uid: item.uid,
                    uss_token: this.props.userSystem.token_us,
                    action: 'update.nodes',
                    confirm: true,
                    change_password: false
                }
                if(item.password){
                    data['password'] = item.password;
                }
                if(item.email) data['email'] = item.email;
                if(item.cookie) data['cookie_default'] = item.cookie;
                if(item.secret_2fa) data['sercet_2fa'] = item.secret_2fa;

                let response = await window.extfbase.request({ method: 'mhcp_account', data: data });
                response = response.data;
                console.log(response);
                this.state.data_success.push(item);
                this.setState({reload: !this.state.reload})
                return resolve(true)
            } catch(err){
                this.state.data_error.push(item);
                this.setState({reload: !this.state.reload})
                return resolve(false);
            }
        })
    }
    render() {
        return (
            <Modal
                title="Đồng bộ dữ liệu tới us"
                visible={this.props.visible}
                onCancel={this.cancel}
                footer={null}
            >
                <Form
                    onFinish={this.syncData}
                    ref={(evt) => this.form = evt}
                    layout="vertical"
                >
                    {
                        !window.extfbase || !window.extfbase.isConnected ?
                            <Alert message={'Extension fbase chưa được connect, vui lòng thử lại!'} type="warning" className="mb-20" />
                            : null
                    }
                    <div className="d-flex" style={{ justifyContent: "space-between" }}>
                        <p>
                            Đang chờ: {this.state.data_pending.length}
                        </p>
                        <p>
                            Thành công: <span className="text-success">{this.state.data_success.length}</span>
                        </p>
                        <p>
                            Lỗi: <span className="text-danger">{this.state.data_error.length}</span>
                        </p>
                    </div>
                    <div className="text-center">
                        <Button type="primary" loading={this.state.loading} htmlType="submit" disabled={!window.extfbase || !window.extfbase.isConnected ? true : false}>
                            Thực hiện
                            </Button>
                        {
                            this.state.loading ?
                                <Button type="info" className="ml-10" onClick={() => {
                                    this.setState({ loading: false })
                                }}>
                                    Dừng tiến trình
                                </Button> : null
                        }
                    </div>
                </Form>
            </Modal>
        )
    }
}
