import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, TreeSelect, Select, notification, Tabs } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { fetchData } from '../../../../lib/helpers';
import { API_URL } from '../../../../lib/api';
import * as XLSX from "xlsx"

const WidgetImport = (props) => {
    const [fileList, setfileList] = useState([]);
    const [file, setfile] = useState(null);
    const [loading, setloading] = useState(false);
    const columns = [
        "device_id"
    ].concat([...new Array(10)].map((key, index) => `column${index + 1}`))
    const [format, setformat] = useState(columns);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);
            console.log('Values form', values);
            if (!values.upload) {
                props.notification({
                    message: 'Lỗi upload',
                    description: 'Bạn chưa chọn file!',
                    type: 'error'
                })
                return;
            }

            let body;

            if(values.upload.file.type == "text/plain"){
                let data = await readFile(values.upload.file);
                body = {
                    data: data,
                    format: values.format
                }

                await fetchData({
                    url: API_URL.userDataLog.saveMany,
                    method: 'post',
                    body: JSON.stringify(body)
                });
            } else {
                let formData = new FormData()
                formData.append("file", values.upload.file)

                await fetchData({
                    url: API_URL.userDataLog.saveMany,
                    method: 'post',
                    body: formData,
                    notAddContentType: true
                });
            }

            notification.success({
                message: "Thông báo",
                description: `Thao tác thành công`
            })

            props.onFinish();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || 'Đã có lỗi xảy ra'
            })
        } finally {
            setloading(false);
        }
    };
    const onFileChange = ({ fileList }) => {
        setfileList([])
        setfile(fileList[0]);
    }

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();

                reader.addEventListener('load', (event) => {
                    resolve(event.target.result);
                })

                reader.readAsText(file)
            } catch (err) {
                reject(err);
            }
        })
    }

    const readFileExcel = (file) => {
        return new Promise((resolve, reject) => {
            try{
                let reader = new FileReader();

                reader.addEventListener('load', (event) => {
                    let data = XLSX.read(event.target.result);
                    let json = XLSX.utils.sheet_to_json(data.Sheets[data.SheetNames[0]])
                    resolve(json);
                })

                reader.readAsArrayBuffer(file)
            } catch(err){
                reject(err);
            }
        })
    }

    return (
        <Modal
            title="Upload"
            visible={props.visible}
            onCancel={props.hide}
            footer={null}
            className={'update-order-tool'}
        >
            <Tabs onChange={(key) => {
                setfileList([])
                setfile(null)
            }}>
                <Tabs.TabPane tab="Upload file text" key="import_txt">
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        ref={form}
                        initialValues={{
                            format: format
                        }}
                    >
                        <Form.Item name="upload" >
                            <Upload.Dragger name="files" beforeUpload={() => false} accept={'.txt'} fileList={fileList} onChange={onFileChange} showUploadList={false}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file txt to this area to upload</p>
                                <p className="ant-upload-hint">{file ? file.name : 'Support for a single or bulk upload.'}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <Form.Item name="format">
                            <Checkbox.Group style={{ width: "100%" }} onChange={(e) => { setformat(e) }}>
                                <Row>
                                    {
                                        columns.map((item) => {
                                            return <Col span={8}>
                                                <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                                    {item}
                                                </Checkbox>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                        >
                            <Input disabled={true} value={format.join(" | ")} />
                        </Form.Item>
                        <div className="text-center">
                            <Button type="primary" loading={loading} htmlType="submit" disabled={!file ? true : false}>
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upload file excel" key="import_xlsx">
                    <Form
                        onFinish={onFinish}
                        layout="vertical"
                        ref={form}
                        initialValues={{
                            format: format
                        }}
                    >
                        <Form.Item name="upload" >
                            <Upload.Dragger name="files" beforeUpload={() => false} accept={'.xlsx, .xls, .csv'} fileList={fileList} onChange={onFileChange} showUploadList={false}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file excel to this area to upload</p>
                                <p className="ant-upload-hint">{file ? file.name : 'Support for a single or bulk upload.'}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <div className='mb-3 text-right'>
                            <a target='_blank' href="/import-data-example.xlsx"><i className='far fa-file-download mr-2'></i>Tải xuống file mẫu</a>
                        </div>
                        <div className="text-center">
                            <Button type="primary" loading={loading} htmlType="submit" disabled={!file ? true : false}>
                                Tiếp tục
                            </Button>
                        </div>
                    </Form>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}

export default WidgetImport;