import { Badge, Button, Result, notification } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { APP_CONFIG } from "../../utils/constant";
import { useHistory, useLocation } from "react-router-dom";
import { fetchData } from "../../lib/helpers";
import { API_URL } from "../../lib/api";

const GoogleApiPage = (props) => {
    const [ userGoogle, setuserGoogle ] = useState();
    const [ loading, setloading ] = useState(false);
    const [ loadingConnect, setloadingConnect ] = useState(false);

    let location = useLocation();
    let navigate = useHistory();

    let params = new URLSearchParams(location.search);
    let token = params.get("code");
    const redirect_uri = `${window.location.origin}${window.location.pathname}`;

    useEffect(() => {
        getData()
        console.log('redirect_uri', redirect_uri)
    }, [ ])

    useEffect(() => {
        if(token){
            loginGoogle(token)
        }
    }, [ token ])

    const loginGoogle = async (token) => {
        try{
            setloadingConnect(true);

            let response = await fetchData({
                url: API_URL.googleapi.login,
                method: "post",
                body: JSON.stringify({
                    code: token,
                    redirect_uri: redirect_uri
                })
            })

            setuserGoogle(response.data)

            notification.success({
                message: "Login Google",
                description: "Bạn đã kết nối tài khoản Google thành công"
            })
        } catch(err){
            notification.error({
                message: "Login Google",
                description: err.message || "Đã có lỗi xảy ra"
            })
        } finally{
            setloadingConnect(false)
            navigate.replace(navigate.location.pathname)
        }
    }

    const getData = async () => {
        try{
            setloading(true);

            let response = await fetchData({
                url: API_URL.googleapi.index
            })

            setuserGoogle(response.data)

        } catch(err){
            notification.error({
                message: "Login Google",
                description: err.message || "Đã có lỗi xảy ra"
            })
        } finally{
            setloading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="block" style={{ minHeight: "800px", maxHeight: "100%" }}>
                <Badge.Ribbon text={"Google Driver"} />
                <div className="block-content block-content-m">
                    <h3 className="block-title mb-20">
                        Kết nối tài khoản Google Driver
                    </h3>
                    <div className="text-center">
                        {
                            !userGoogle ? <>
                                <Result 
                                    status={"info"}
                                    title="Bạn chưa kết nối với tài khoản Google nào"
                                    subTitle="Kết nối ngay bây giờ"
                                />
                            </> : <>
                                <Result 
                                    status={"success"}
                                    title={`Bạn đang kết nối với tài khoản ${userGoogle.fullname}`}
                                />
                            </>
                        }
                        <Button type="primary" 
                            onClick={(e) => {
                                window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/drive%20https://www.googleapis.com/auth/drive.metadata.readonly&prompt=consent&access_type=offline&redirect_uri=${redirect_uri}&response_type=code&client_id=${APP_CONFIG.GOOGLE_CLIENT_ID}`
                            }}
                            loading={loadingConnect}
                        >Đăng nhập với Google</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GoogleApiPage;
