import { createStore, applyMiddleware } from "redux";
import createSagaMiddlerware from "redux-saga";
import rootReducers from "./rootReducers";
import rootSaga from "./rootSaga";

const sagaMiddlerware = createSagaMiddlerware();
const store = createStore(rootReducers, applyMiddleware(sagaMiddlerware));

sagaMiddlerware.run(rootSaga);

export default store;