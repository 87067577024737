import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, Form, Input, Radio, Alert} from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class FormMoveToUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type_move: 1,
            link_move: ""
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });
            values['list_ids'] = this.props.listIdChecked;

            let result = await this.props.fetchData({
                url: 'api/v1/user/move/data',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            this.setState({ loading: false }, () => {
                if(values['type_move'] == 2){
                    this.setState({
                        link_move: `${window.location.origin}?fbase_ref=${result.ref}`
                    })
                } else {

                }
                this.props.notification({ 
                    description: 'Thao tác thành công!',
                    type: 'success',
                    key: 'success',
                    message: 'Chuyển dữ liệu'
                })
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error',
                    message: 'Chuyển dữ liệu'
                })
            });
        }
    };
    cancel = () => {
        this.props.resetData();
        this.form.resetFields();
        this.props.hide();
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.setState({
                link_move: null
            })   
        }
    }
    copyToClipboard = (value) => {
        let copyText = document.querySelector('#input-copy-text');

        if(!copyText) return;
        copyText.value = value;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy")
        this.props.notification({
            description: 'Sao chép thành công',
            message: 'Sao chép',
            type: 'success'
        })
    }
    render() {
        return (
            <Modal
            title={`Chuyển ${this.props.listIdChecked.length} dữ liệu`}
            visible={this.props.visible}
            onCancel={this.cancel}
            footer={null}
            >
                <textarea id="input-copy-text" style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px", top: "-100px" }} onKeyPress={(e) => { e.preventDefault(); return false}} readOnly></textarea>
                <Form
                        onFinish={this.onFinish}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            type_move: this.state.type_move
                        }}
                        layout="vertical"
                    >
                        <Form.Item 
                            name="type_move"
                        >
                            <Radio.Group onChange={(e) => {this.setState({ type_move: e.target.value })}}>
                                <Radio value={1}>Chuyển đến username</Radio>
                                <Radio value={2}>Tạo link</Radio>
                            </Radio.Group>
                        </Form.Item >
                        {
                            this.state.type_move == 1 ?
                            <Form.Item 
                                name="user_name"
                                label="User name nguời nhận"
                                rules={[{ required: true, message: 'Bạn phải nhập username đăng nhập của người nhận!' }]}
                            >
                                <Input placeholder="Nhập username" />
                            </Form.Item> : null
                        }
                        {
                            this.state.link_move && this.state.type_move == 2 ? 
                            <div className="mb-10" >
                                <Alert type="success" message={this.state.link_move} />
                            </div>
                            : null
                        }
                        <div className="text-center">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                Xác nhận
                            </Button>
                            {
                                this.state.link_move && this.state.type_move == 2 ? <Button type="success" onClick={() => this.copyToClipboard(this.state.link_move)}>
                                Copy link
                            </Button> : null
                            }
                        </div>
                    </Form>
            </Modal>
        )
    }
}
