import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Select, TreeSelect, Radio } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class FormGetCookie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalGet: 0
        }
        this.form = null;
    }
    componentWillMount = () => {

    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.getData();
        }
    }
    onFinish = async (values) => {
        try {
            console.log(values);
            
            if (!window.extfbase || !window.extfbase.isConnected) {
                throw ({ message: 'Vui lòng kiểm tra tiện ích Fbase phiên bản mới nhất của chúng tôi!' });
            }

            this.props.runGetCookie({
                run: this.state.totalGet,
                max_tab: values['max_tab'],
                time_delay: values['time_delay'],
                max_nick_delay: values['max_nick_delay'] || 10,
                max_nick_delay_time: values['max_nick_delay_time'] || 1,
                max_nick_checkpoint: values['max_nick_checkpoint'],
                max_nick_checkpoint_type: values['max_nick_checkpoint_type'],
                type: "get_cookie",
                data: {
                    folder_id: values['folder_id'],
                    min_friend_move_folder: values['min_friend_move_folder'] ? parseInt(values['min_friend_move_folder']) : undefined,
                    url_login: values['url_login'],
                    login_success_delete_cookie: values['login_success_delete_cookie'],
                    active_tab: values['active_tab'],
                    get_friends: values['get_friends'] || false,
                    enable_secret_2fa: values['enable_secret_2fa'] || false
                },
                query: {
                    is_device: values['is_device']
                }
            });
        } catch (err) {
            this.props.notification({
                type: 'warning',
                message: 'Trình get cookie',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    };
    getData = async () => {
        try {
            let result = await this.props.fetchData({
                url: `api/v1/user_fb/new?folder_id=${this.props.currentFolder ? this.props.currentFolder : ''}&count=1`,
                method: 'get'
            });
            this.setState({
                totalGet: result.data
            })
        } catch (err) {
            this.props.notification({
                type: 'warning',
                message: 'Trình get cookie',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    stop = () => {
        this.setState({
            loading: false
        })
    }
    render() {
        let { dataRunGetCookie } = this.props;
        return (
            <Modal
                title="Trình get cookie"
                visible={this.props.visible}
                onCancel={() => {
                    this.form.resetFields();
                    this.props.hide();
                }}
                footer={null}
                className="modal-getcookie"
                width={900}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        max_tab: 1,
                        time_delay: '1-2',
                        max_nick_checkpoint_type: 1,
                        get_friends: true,
                        enable_secret_2fa: true
                    }}
                    layout="vertical"
                >
                    <h3 className="block-title mb-5">Danh sách tài khoản cần check: {dataRunGetCookie ? dataRunGetCookie.run : this.state.totalGet}</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div>
                                {/* <div className="block-header block-header-default">
                                    <h3 className="block-title">Thống kê</h3>
                                </div> */}
                                <div className="block-content mb-5 bt-1" style={{ border: "1px solid #f6f7f9", borderTop: "0px" }}>
                                    <p className="text-success mb-5">Thành công: {dataRunGetCookie ? dataRunGetCookie.success : 0}</p>
                                    <p className="text-danger mb-5">Checkpoint: {dataRunGetCookie ? dataRunGetCookie.checkpoint : 0}</p>
                                    <p className="text-danger mb-5">Die: {dataRunGetCookie ? dataRunGetCookie.die : 0}</p>
                                    <p className="text-danger mb-5">Lỗi: {dataRunGetCookie ? dataRunGetCookie.error : 0}</p>
                                    <p className="text-danger mb-5">Không xác định: {dataRunGetCookie ? dataRunGetCookie.other : 0}</p>
                                </div>
                                <Form.Item
                                    label="Chuyển dữ liệu"
                                >
                                    <Form.Item name="min_friend_move_folder">
                                        <InputNumber min={0} style={{ width: "100%" }} placeholder="Min friend" />
                                    </Form.Item>
                                    <Form.Item name="folder_id">
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={this.props.listFolder}
                                            placeholder="Folder id"
                                            treeDefaultExpandAll={false}
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) => {
                                                if (option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value == input) {
                                                    return option
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item name="is_device" label="Nick có device" valuePropName="checked">
                                    {/* <Radio.Group>
                                        <Radio value={false}>Không có device</Radio>
                                        <Radio value={true}>Có device</Radio>
                                    </Radio.Group> */}
                                    <Checkbox value={true}>Có device</Checkbox>
                                </Form.Item>
                                <Form.Item name="active_tab" valuePropName="checked">
                                    <Checkbox value={true}>Active tab</Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                name="url_login"
                                label="Link Login"
                            >
                                <Input.TextArea  style={{minHeight: "100px"}}/>
                            </Form.Item>
                            <Form.Item
                                name="time_delay"
                                label="Time delay 1 tiến trình(s)"
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                label="Gặp n (nick) checkpoint thì dừng"
                                className="mb-0"
                            >
                                <div className="row">
                                    <Form.Item
                                        name="max_nick_checkpoint"
                                        className="col-6"
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item name="max_nick_checkpoint_type" className="col-6">
                                        <Select>
                                            <Select.Option value={1}>Không liên tiếp</Select.Option>
                                            <Select.Option value={2}>Liên tiếp</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Thực hiện n (nick) -> nghỉ s (giây)"
                                className="mb-0"
                            >
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="max_nick_delay">
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="max_nick_delay_time">
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item name="login_success_delete_cookie" valuePropName="checked">
                                <Checkbox value={true}>Xóa cookie sau khi get thành công</Checkbox>
                            </Form.Item>
                            <Form.Item name="get_friends" valuePropName="checked">
                                <Checkbox value={true}>Get số lượng bạn bè</Checkbox>
                            </Form.Item>
                            <Form.Item name="enable_secret_2fa" valuePropName="checked">
                                <Checkbox value={true}>Bật secret 2fa</Checkbox>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="text-right">
                        <Button type="primary" loading={this.props.run} htmlType="submit" id="btn-get-cookie">
                            Tiếp tục
                        </Button>
                        {this.props.run ? <Button id="btn-stop-get-cookie" className="ml-10" type="danger" onClick={this.props.stopGetCookie}>
                            Dừng
                        </Button> : null}
                    </div>
                </Form>
            </Modal>
        )
    }
}
